
/**
 * globally unique identifier
 * @typedef {string} GUID
 */

/**
 * Added/updated Dial Value
 * @typedef {Object} OrderItemPropertyModel
 * @property {GUID} ID The unique ID of the property.
 * @property {Object} Value The value of the property.
 */

/**
 * Delivery methods
 * @enum {number}
 */
const DeliveryMethod = {
  /** Shipping delivery method */
  Shipping: 1,
  /** Mailing delivery method */
  Mailing: 2,
};

/**
 * Provides information about a component order item.
 * @typedef {Object} SubOrderItemModel
 * @property {boolean} IsIncluded TIndicates whether the component order item is included in the parent order item.
 * @property {GUID} ID The unique ID of the order item.
 * @property {number} FriendlyID The friendly ID of the order item.
 * @property {GUID} ProductID The unique ID of the order item's product.
 * @property {number} Quantity The number of units of the product to be purchased in the order item.
 * @property {SubOrderItemModel} Items The component order items of the order item.
 * @property {DeliveryMethod} DeliveryMethod The selected delivery method of the order item.
 * @property {GUID} DeliveryServiceID The selected delivery service id of the order item.
 * @property {OrderItemPropertyModel} Properties The order item's property values.
 */

/**
 * Provides information about an order item.
 * @typedef {Object} OrderItemModel
 * @property {GUID} ID The unique ID of the order item.
 * @property {number} FriendlyID The friendly ID of the order item.
 * @property {GUID} ProductID The unique ID of the order item's product.
 * @property {number} Quantity The number of units of the product to be purchased in the order item.
 * @property {SubOrderItemModel} Items The component order items of the order item.
 * @property {DeliveryMethod} DeliveryMethod The selected delivery method of the order item.
 * @property {GUID} DeliveryServiceID The selected delivery service id of the order item.
 * @property {OrderItemPropertyModel} Properties The order item's property values.
 */

/**
 * Provides information about a product's price and tax.
 * @typedef {Object} ProductPriceModel
 * @property {number} Price The price of the product excluding tax, in the store's primary currency.
 * @property {number} Tax The tax of the product, in the store's primary currency.
 */

/**
 * Provides information about an order item's price and tax.
 * @typedef {Object} OrderItemPriceModel
 * @property {ProductPriceModel} Price The price of the order item. Includes delivery price. The value is undefined (equals null) if the user does not have permission to view the product price.
 * @property {boolean} IsMinimumPrice Indicates whether the price is the product's minimum price and not the actual price. The minimum price is returned when the actual price cannot be calculated. For an example, when a property does not have a valid value.
 * @property {number} MailingFee The price of mailing delivery of the order item.
 * @property {number} ProductPrice The price of the product. Does not include the delivery price.
 */

/**
 * Provides information about an order item's submission status.
 * @typedef {Object} OrderItemStatusModel
 * @property {string} OrderItemID The unique ID of the order item.
 * @property {date} SubmittedDate The date when the order item was submitted.
 */

/**
 * Provides the order approval information.
 * @typedef {Object} OrderApprovalModel
 * @property {number} OriginalOrderFriendlyID The friendly ID of the original order.
 * @property {string} OriginalOrderID The ID of the original order.
 */

/**
 * Provides information about a product's property for cart.
 * @typedef {Object} CartItemPropertyModel
 * @property {string} Name The property name.
 * @property {string} Value The property value.
 * @property {string} ComponentRange Represents the page range when using a composite product.
 * @property {string} ComponentName The name of the component of the composite product.
 */

/**
 * Provides information about a product unit type.
 * @typedef {Object} ProductUnitTypeModel
 * @property {string} Name The singular form of the product unit name (e.g. "Box", "Item", "Pack", "Piece").
 * @property {string} PluralName The plural form of the product unit name (e.g. "Boxes", "Items", "Packs", "Pieces").
 */

/**
 * Provides information about a product unit.<br/> The unit can be a single item, multiple items or a pack of items.
 * @typedef {Object} ProductUnitModel
 * @property {number} ItemQuantity The number of items that make up the unit.
 * @property {ProductUnitTypeModel} ItemType The type of item.
 * @property {ProductUnitTypeModel} PackType The type of pack. The value is undefined (equals null) for units that are not sold in packs.
 */

/**
 * Provides information about a product.
 * @typedef {Object} CartProductModel
 * @property {GUID} ProductID The unique ID of the product.
 * @property {string} Name The name of the product.
 * @property {string} CatalogNumber The catalog number of the product. The value is undefined (equals null) if the user does not have permission to view the catalog number.
 * @property {ProductUnitModel} Unit Provides information about a product unit. The unit can be a single item, multiple items or a pack of items.
 * @property {boolean} HasPricing Indicates whether a price was defined for the product.
 */

/**
 * Provides information about the subitem of the cart item.
 * @typedef {Object} CartSubItemModel
 * @property {CartProductModel} Product The product of the cart subitem.
 * @property {number} Quantity The quantity of units of the product per parent container.
 */

/**
 * Provides information about the thumbnail of the cart item.
 * @typedef {Object} CartItemThumbnailModel
 * @property {string} Url The URL for providing file content of the image of the cart item.
 */

/**
 * Enum values present async activity status.
 * @enum {number}
 */
const ActivityStatus = {
  InProgress: 1,
  Completed: 2,
  Failed: 3,
  PartiallyFailed: 4
};

/**
 * Provides information about an attribute.
 * @typedef {Object} AttributeModel
 * @property {string} Name The name of the attribute.
 * @property {string} Value The value of the attribute.
 */

/**
 * Provides information about the cart item.
 * @typedef {Object} CartItemModel
 * @property {string} OrderItemID The unique ID of the order item.
 * @property {CartProductModel} Product The product of the cart item.
 * @property {string} Nickname The nickname of the cart item.
 * @property {boolean} Checked Indicates whether the cart item is checked.
 * @property {number} OriginalOrderFriendlyID The friendly ID of original order. Can be null. It contains EncryptedOrderID (can be displayed in the UI) in case the orderItem was duplicated from an existing order.
 * @property {string} EditUrl The URL for editing the order item.
 * @property {number} QuantityPerRecipient The quantity of units of the product per recipient. Can be null in case of the dynamic product configured: Copies per record are based on "Value Defined in Process Job"
 * @property {number} NumRecipients The total number of the recipients.
 * @property {number} Quantity The number of units of the product to be purchased in the cart item.
 * @property {CartItemPropertyModel[]} Properties The cart item's property values.
 * @property {CartSubItemModel[]} SubItems The list of cart subitems of the cart item.
 * @property {CartItemThumbnailModel} Thumbnail The cart item thumbnail information.
 * @property {ActivityStatus} ProofStatus The cart item's proof status.
 * @property {AttributeModel[]} Attributes The cart item's attributes.
 */

/**
 * Provides information about the cart of the user.
 * @typedef {Object} CartModel
 * @property {OrderApprovalModel} OrderApproval The order approval information. Can be null if there is no approval.
 * @property {string} CheckoutUrl The URL to the first page of the checkout wizard.
 * @property {CartItemModel[]} Items The list of the items in the cart.
 * @property {string} Description The text that will be displayed in the Shopping Cart.
 * @property {Array} Activities Active background tasks of the current user, related to the shopping cart
 */

/**
 * Error type indication.
 * @enum {number}
 */
const ErrorType = {
  /** Indicates whether inventory for the selected product have been exceeded */
  InventoryExceeded: 1,
  /** Indicates whether inventory for the selected product is Out Of Stock */
  InventoryOutOfStock: 2,
  /** Indicates whether inventory for the selected product have been exceeded and delivery might be delayed */
  InventoryDelayed: 3,
  /** Indicates whether the selected product is not available */
  ProductNotAvailable: 4,
  /** Indicates whether one of the selected product components is not available */
  ProductComponentNotAvailable: 5,
  /** Indicates whether maximum quantity for the selected product have been exceeded */
  MaxUnitsExceeded: 6,
  /** Indicates whether minimum quantity for the selected product is not reached */
  MinUnitsNotReached: 7,
  /** Indicates whether product units have been changed */
  MinUnitsNotReached: 8,
  /** Indicates whether maximum quantity for the selected product have been exceeded */
  MaxUnitsExceededForOrder: 9,
  /** Indicates whether the selected recipient list model in not available */
  RecipientListModelInvalid: 10,
  /** Indicates whether the selected delivery method in not available */
  DeliveryMethodInvalid: 11,
  /** Indicates whether order item files have been deleted by GDPR */
  GdprFilesDeleted: 12,
  /** Indicates whether product properties options have been changed */
  ProductOptionsChanged: 13,
  /** Indicates whether inventory of the selected product components is Out Of Stock */
  InventoryComponentOutOfStock: 14,
  /** Indicates whether split shipping is forced by property selection */
  ForcedSplitShipping: 15,
};

/**
 * Indicates error level.
 * @enum {number}
 */
const ErrorLevel = {
  /** Low error level. */
  Warning: 1,
  /** High error level. */
  Error: 2,
}

/**
 * Provides information about an error.
 * @typedef {Object} ErrorModel
 * @property {ErrorType} Type The type of the error
 * @property {ErrorLevel} Level The level of the error
 * @property {string} Message The message of the error
 */

/**
 * Provides information about an error of the order item.
 * @typedef {Object} CartItemValidationModel
 * @property {string} OrderItemID The unique ID of the order item.
 * @property {string} Error The error details.
 */

/**
 * Provides pricing information for a Shopping Cart item.
 * @typedef {Object} CartItemPriceModel
 * @property {GUID} OrderItemID The unique ID of the order item.
 * @property {number} Price The price of the order item.
 * @property {number} Tax The tax of the order item.
 * @property {boolean} IsChanged Indicates whether the product price configuration has been changed
 * @property {boolean} IsValid Indicates whether the order item price is valid
 */

/**
 * Provides pricing information for the Shopping Cart.
 * @typedef {Object} CartPriceModel
 * @property {CartItemPriceModel[]} Items Provides pricing information for the Shopping Cart items.
 */

/**
 * Provides information that can be updated for the cart item.
 * @typedef {Object} CartItemUpdateModel
 * @property {GUID} OrderItemID The unique ID of the order item.
 * @property {boolean} IsChecked Indicates whether the item is marked in the cart. If null is specified, the value does not change, the original value is preserved.
 * @property {string} Nickname The nickname of the cart item. If null is specified, the value does not change, the original value is preserved.
 * @property {GUID} ListID The unique ID of the submission list.
 */

/**
 * Provides information that can be updated for the wish list item.
 * @typedef {Object} WishListItemUpdateModel
 * @property {string} Nickname The nickname of the wish list item. If null is specified, the value does not change, the original value is preserved.
 */

/**
 * Provides cart list DTO.
 * @typedef {Object} CartListCreateResponseModel
 * @property {GUID} ID Cart list ID.
 * @property {string} ExternalID Cart list external ID.
 * @property {string} Name Cart name.
 * @property {string} ExternalUrl Cart list external URL.
 * @property {Date} ExportDate Cart list export date.
 * @property {Date} ModificationDate Cart list modification date.
 * @property {Date} SortingDate Cart list sorting date.
 */

/**
 * Provides cart list DTO.
 * @typedef {Object} CartListDetailsModel
 * @property {GUID} ID Cart list ID.
 * @property {string} ExternalID Cart list external ID.
 * @property {string} Name Cart list name.
 * @property {string} ExternalUrl Cart list external URL.
 * @property {boolean} IsDeleted Flag that indicate is cart list was deleted.
 * @property {Date} ExportDate Cart list export date.
 * @property {Date} ModificationDate Cart list modification date.
 * @property {Date} SortingDate Cart list sorting date.
 * @property {CartItemValidationModel[]} ItemValidations Cart list's validation summary for the assigned order product(s).
 * @property {CartItemPriceModel[]} ItemPrices Cart list's pricing info for the assigned order product(s).
 */

/**
 * Provides cart list model for create.
 * @typedef {Object} CartListCreateModel
 * @property {string} Name Cart list name. Required. Unique.
 * @property {string} ExternalID Cart list external ID. Optional.
 * @property {string} ExternalUrl Cart list external URL. Optional.
 */

/**
 * Provides cart list DTO.
 * @typedef {Object} CartListModel
 * @property {GUID} ID Cart list ID.
 * @property {string} ExternalID Cart list external ID.
 * @property {string} Name Cart list name.
 * @property {string} ExternalUrl Cart list external URL.
 * @property {Date} ExportDate Cart list export date.
 * @property {Date} ModificationDate Cart list modification date.
 * @property {Date} SortingDate Cart list sorting date.
 * @property {number} ItemsCount Amount of the order products assigned to the cart list.
 */

/**
 * Provides information that was took from the export cart web-hook.
 * @typedef {Object} CartListExportResponseModel
 * @property {string} ExternalUrl External URL from the cart export web-hook.
 * @property {string} RedirectUrl Redirect URL from the cart export web-hook.
 * @property {Date} ExportDate Export date from the cart export web-hook.
 */

/**
 * Provides the result of the activity creation.
 * @typedef {Object} ActivityCreateResponseModel
 * @property {GUID} ActivityID The unique ID of the activity
 */

/**
 * Provides a user's summary of orders.
 * @typedef {Object} UserOrdersSummaryModel
 * @property {number} RejectedOrderCount The number of rejected orders of the user.
 * @property {number} PendingApprovalOrderCount The number of orders pending for approval by the user.
 */

/**
 * A representation of the file entity
 * @typedef {Object} FileInfoModel
 * @property {string} FileName The name of the file.
 * @property {number} Size The size of the file in KB, rounded up to the smallest following integer value.
 */

/**
 * Added/updated Dial Value for FileAttachment.
 * @typedef {Object} OrderItemFileAttachmentPropertyModel
 * @property {FileInfoModel[]} FileInfoList Additional information on the file.
 * @property {string} ID The unique ID of the property.
 * @property {Object} Value The value of the property.
 */

/**
 * @typedef {Object} DeliveryServicesModel
 * @property {GUID} ID
 * @property {string} Name
 */

export const OrdersTypes = {}