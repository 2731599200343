import React, { useRef } from 'react'
import Layout from '../components/layout'
import './Policy.scss'

const PolicyReturns = (props) => {
    return (
      <Layout {...props} className="policy">
        <div className='main-content'>
            <h1 className='top-fix'>Returns Policy</h1>
            <p>14.13. Claims under this Agreement shall only be entertained if You have, within 30 days of the alleged breach or defect occurring given Us 30 (thirty) 
                days written notice to rectify any alleged defect or breach of the agreement. Full details of the alleged breach or defect must be given as well as 
                photographs. The so-called defective goods must be returned at Your cost to Us for inspection within 5 (five) days of lodging the complaint.</p>
            <p>14.14. Any refunds agreed to by Us shall be in the form of user’s credit redeemable only on the Platform.</p>
            <p>14.15. Only in the event of printed items being defective due to the printing process or if damaged during shipping, may an Order for Services be eligible 
                for a refund other than set out above, in which event you must contact our customer support website or the email set out below, within 14 days from the 
                date of receipt of the goods.</p>
        </div>
      </Layout>
    )
}
  
export default PolicyReturns