
import * as types from './actionsTypes'
import defaultState from './defaultState'
import { merge, parseStorePreferences } from '../utils/utils'


// REDUCERS
export default (state = defaultState, action) => {
  switch (action.type) {
    case '@@INIT_STATE':
      return Object.assign({}, state, action.data, { initState: false })
    case types.LOAD_CURRENT_STORE:
      return Object.assign({}, state, { currentStore: action.data })
    case types.LOAD_CURRENT_USER:
      const currentUser = action.data
      currentUser.Preferences = parseStorePreferences(currentUser?.Preferences)
      return Object.assign({}, state, { currentUser })
    case types.LOAD_USER_ORDERS_SUMMARY:
      return Object.assign({}, state, { userOrdersSummary: action.data })
    case types.LOAD_CATEGORIES:
      return Object.assign({}, state, { categories: action.data })
    case types.LOAD_CATEGORIES_TREE:
      const categoriesTree = action.data
      const firstLevelCategories = categoriesTree.map((node) => node.Category)
      return Object.assign({}, state, { categoriesTree: categoriesTree, categories: firstLevelCategories })
    case types.LOAD_SUB_CATEGORIES:
      return Object.assign({}, state, { subCategories: action.data })
    case types.LOAD_CULTURES:
      return Object.assign({}, state, { cultures: action.data })
    case types.LOAD_CURRENCIES:
      return Object.assign({}, state, { currencies: action.data })
    case types.LOAD_CART_ITEMS_COUNT:
      return Object.assign({}, state, { cartItemsCount: action.data })
    case types.SET_CURRENT_CULTURE:
      return Object.assign({}, state, { currentCulture: action.data })
    case types.SET_CURRENT_CULTURE_BY_LANGUAGE:
      const culture = action.data && state.cultures.length > 0 ? state.cultures.filter(c => c.LanguageCode === action.data)[0] : null
      return Object.assign({}, state, { currentCulture: culture })
    case types.SET_CURRENT_CURRENCY:
      const currency = action.data ? action.data : (state.currencies.length > 0 ? state.currencies.filter(c => c.ID === state.currentStore.PrimaryCurrencyID)[0] : null)
      return Object.assign({}, state, { currentCurrency: currency })
    case types.LOAD_CURRENT_CATEGORY:
      return Object.assign({}, state, { currentCategory: action.data, subCategories: null })
    case types.LOAD_PRODUCTS:
      return Object.assign({}, state, { products: action.data.products })
    case types.SET_CUSTOM_VAR:
      const mergedCustomState = Object.assign(state.customState, action.data)
      return Object.assign({}, state, { customState: mergedCustomState })
    case types.DELETE_CUSTOM_VAR:
      const customStateDup = Object.assign({}, state.customState)
      delete customStateDup[action.data.name]
      return Object.assign({}, state, { customState: customStateDup })
    case types.MERGE_CUSTOM_STATE:
      return Object.assign({}, state, { customState: merge(state.customState, action.data) })
    case types.CLEAR_STATE:
      return Object.assign({}, state, defaultState)
    case types.GET_ASSETS:
      return Object.assign({}, state, { assets: action.data })
    case types.GET_ASSET:
      return Object.assign({}, state, { selectedAsset: action.data })
    case types.DELETE_ASSET:
      return Object.assign({}, state, { assets: state.assets.filter(a => a.AssetID !== action.data) })
    case types.UPLOAD_ASSET:
      return Object.assign({}, state, { assets: [action.data, ...state.assets] })
  }

  return state
}
