import providerState from './providerState'
import { GET_ASSETS, GET_ASSET, DELETE_ASSET, UPLOAD_ASSET } from '../redux/actionsTypes'
import { getAssets, getAsset, deleteAsset, uploadAsset } from '../api/assets'

const { AssetListItemResponseModel, AssetResponseModel, GUID } = require('./../types/assets.js')

/**
 * Represent the assets logic in the system
 * @class
 */

class Assets {

  /**
   * Gets collection of the graphic assets by dial ID.
   * @param {string} propertyID Graphic dial ID
   * @returns {AssetListItemResponseModel[]} Returns collection of the assets.
   * @description Set *state.assets* with the requested model as {@link AssetListItemResponseModel}
   */
  async getAssets (propertyID) {
    const assets = await getAssets(propertyID)
    providerState.dispatch({ type: GET_ASSETS, data: assets })
    return assets
  }

  /**
   * Gets graphic asset detailed data by asset ID.
   * @param {string} assetID Graphic asset ID.
   * @returns {AssetResponseModel} Returns graphic assets detailed info.
   * @description Set *state.selectedAsset* with the requested assets model as {@link AssetResponseModel}
   */
  async getAsset (assetID) {
    const asset = await getAsset(assetID)
    providerState.dispatch({ type: GET_ASSET, data: asset })
    return asset
  }

  /**
   * Deletes uploaded graphic asset by ID.
   * @param {string} assetID Graphic asset ID.
   * @returns {string} Returns deleted graphic asset ID.
   * @description Filters the list of assets, removing the asset with the specified identifier.
   */
  async deleteAsset (assetID) {
    await deleteAsset(assetID)
    providerState.dispatch({ type: DELETE_ASSET, data: assetID })
    return assetID
  }

  /**
   * Uploads graphic asset.
   * @param {string} propertyID Graphic dial ID
   * @param {File} data File to upload
   * @param {boolean} replaceIfExists Show if asset should be replaced
   * @returns {AssetListItemResponseModel} Returns uploaded asset.
   * @description Updates *state.assets* collection with the new graphic asset model as {@link AssetListItemResponseModel2}
   */
  async uploadAsset (propertyID, data, replaceIfExists) {
    const asset = await uploadAsset(propertyID, data, replaceIfExists)
    providerState.dispatch({ type: UPLOAD_ASSET, data: asset })
    return asset
  }
}

export default new Assets()
