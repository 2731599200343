/**
 * globally unique identifier
 * @typedef {string} GUID
 */

/**
 * The types of title tags.
 * @enum {number}
 */
const SeoTitleTagType = {
  /** Do not add tag to titles of webpages. */
  NotIncluded: 1,
  /** Add tag as the suffix to the webpage title. */
  Suffix: 2,
  /** Add tag as the prefix to the webpage title. */
  Prefix: 3,
};

/**
 * Defines whether the tax appears as part of the price.
 * @enum {number}
 */
const TaxFormatType = {
  /** Price does not include tax. */
  Exclude: 1,
  /** Price includes tax. */
  Include: 2,
  /** Custom tax format built using the following placeholders: {Price}, {Tax} and {PriceIncludingTax}.<br/> E.g. "{Price} + {Tax} VAT = {PriceIncludingTax}" */
  Custom: 3,
};

/**
 * Store types.
 * @enum {number}
 */
const StoreType = {
  /** Business to business store. */
  B2B: 1,
  /** Business to customer store. */
  B2C: 2,
  /** In this store type uStore integrates with external e-commerce systems. */
  uStoreConnect: 3,
  /** In this store type uStore integrates with external e-commerce systems with extended functionality. */
  uStoreConnectEnterprise: 4,
};

/**
 * Gender options.
 * @enum {number}
 */
const GenderType = {
  /** Male. */
  Male: 1,
  /** Female. */
  Female: 2,
};

/**
 * Provides information about the SEO configuration of a store.
 * @typedef {Object} StoreSeoConfigurationModel
 * @property {string} TitleTag The tag that is added to the titles of all webpages of the store.
 * @property {SeoTitleTagType} TitleTagType The type of title tag.
 * @property {string} Title The title of a webpage.
 * @property {string} Description The description of a webpage.
 */

/**
 * Provides information about a store.
 * @typedef {Object} StoreModel
 * @property {GUID} ID The unique ID of the store.
 * @property {number} FriendlyID The friendly ID of the store.
 * @property {string} Name The name of the store.
 * @property {string} Description The description of the store.
 * @property {string} ExternalID The ID of the store in an external application.
 * @property {GUID} PrimaryCurrencyID The unique ID of the primary currency in the store. The billing will always be performed using the primary currency. Secondary currencies are used for display only.
 * @property {boolean} ShowCurrencyCode Determines whether to show currency abbreviation.
 * @property {boolean} RequireOrderApproval Indicates if orders in the store should be approved prior to submission.
 * @property {TaxFormatType} TaxFormatType Defines whether the tax appears as part of the price in the store products.
 * @property {string} TaxFormat The tax display format built using the following placeholders: {Price}, {Tax} and {PriceIncludingTax}.
 * @property {StoreSeoConfigurationModel} SeoConfiguration The SEO configuration of the store.
 * @property {StoreType} StoreType The store type.
 */

/**
 * Provides information about a user's role in a store.
 * @typedef {Object} UserRolesModel
 * @property {boolean} Shopper Indicates if the user has permission to purchase products in the store.
 * @property {boolean} Approver Indicated whether the user has permissions to approve or disapprove purchases in the store.
 */

/**
 * Provides information about a user group.
 * @typedef {Object} UserGroupModel
 * @property {string} Name The name of the user group.
 */

/**
 * Provides information about a custom user property.
 * @typedef {Object} CustomUserPropertyModel
 * @property {number} ID The ID of the custom user property.
 * @property {string} Name The name of the custom user property.
 * @property {string} Value The value of the custom user property.
 */

/**
 * Provides information about a user.
 * @typedef {Object} UserModel
 * @property {GUID} ID The unique ID of the user.
 * @property {number} FriendlyID The friendly ID of the user.
 * @property {string} FirstName The user's first name.
 * @property {string} LastName The user's last name.
 * @property {string} Email The user's email address.
 * @property {string} ExternalID The ID of the user in an external application.
 * @property {UserRolesModel} Roles The user's role(s) in the store.
 * @property {UserGroupModel[]} Groups The list of user groups to which the user belongs.
 * @property {string} PhoneNumber The user's phone number.
 * @property {string} MobileNumber The user's mobile number.
 * @property {string} FaxNumber The user's fax number.
 * @property {string} CompanyName The user's company name.
 * @property {string} Department The user's department.
 * @property {string} JobTitle The user's job title.
 * @property {GenderType} Gender The user's gender.
 * @property {Date} Birthday The user's birthday.
 * @property {CustomUserPropertyModel[]} CustomProperties The user's custom properties.
 * @property {boolean} IsAnonymous Indicates whether the user is anonymous.
 * @property {string} Preferences User's preferences for UI based on the store.
 */

/**
 * Provides information about a culture.
 * @typedef {Object} CultureModel
 * @property {GUID} ID The unique ID of the culture.
 * @property {number} FriendlyID The friendly ID of the culture.
 * @property {string} Name The name of the culture as written in the culture's language (e.g. "Español").
 * @property {string} LanguageCode The language code according to the ISO 3166-1 standard (e.g. "en-US").
 * @property {string} CountryCode The country code according to the ISO 3166-1 standard (e.g. "US").
 * @property {string} CurrencyFormat The currency display format built using the following placeholders:
 *                                   {Amount} and {Symbol}.
 *                                   E.g.: "{Symbol}{Amount}" will be shown as $100
 *                                         "{Amount}{Symbol}" will be shown as 100$
 * @property {string} DecimalSeparator The character used as the decimal separator in the prices. Usually it is a comma or a period.
 * @property {number} DecimalPlaces The number of decimal places in the price after the decimal separator.
                                    E.g.: $2.50 will be shown if the value is 2 and $2.5 will be shown if the value is 1.
 */

/**
 * Provides information about a currency.
 * @typedef {Object} CurrencyModel
 * @property {GUID} ID The unique ID of the currency.
 * @property {number} FriendlyID The friendly ID of the currency.
 * @property {string} Name The name of the currency.
 * @property {string} Symbol The symbol of the currency.
 * @property {string} Code The currency code according to the ISO 4217 standard (e.g. "USD").
 * @property {number} ConversionRate The conversion rate from the store's primary currency to this currency. E.g. If the primary currency is USD, this currency is EUR and the conversion rate is 0.886285, then 1 USD = 0.886285 EUR.
 */

/**
 * Provides information about the cart items count.
 * @typedef {Object} CartIndicatorModel
 * @property {number} ItemsCount The total number of items in the cart.
 */

/**
 * Provides information about a security token (a unique and non-guessable value) that is used for user authentication.
 * @typedef {Object} SecurityTokenModel
 * @property {string} Token The security token.
 */

export const StoreTypes = {}