import stateService from './services/providerState'
import configService from './services/config'
import bindingService from './services/binding'
import contexService from './services/context'
import api from './services/api'
import initialStateService from './services/initialState'
import newState from './services/newState'

/**
 * UStoreProvider is an independent entity that facilitates connectivity to the uStore API and also manages the state of your application.
 *
 * {{links}}
 */
class Provider {
  constructor () {
    this.stateService = stateService
    this.binding = bindingService
    this.initialState = initialStateService
    this.configService = configService
    this.contextService = contexService
  }

  async init (runtimeConfig, params) {
    if (runtimeConfig) {
      this.configService.set(runtimeConfig)
    }
    if (params) {
      this.contextService.set(params)

      if (this.stateService.get().initState) {
        await this.initialState.loadInitialState()
      }
    }
  }

  get api () {
    return api
  }

  get state () {
    return newState
  }
}


export const UStoreProvider = new Provider()
// expose UStoreProvider on window so standalone component will have access to it
// which will allow them to register and let the provider add model binding and
// action changing
if (typeof window !== 'undefined') {
  window.UStoreProvider = UStoreProvider
}
