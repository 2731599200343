import http from './http'
import config from '../services/config'

const duplicateItemMocks = [
  {Name: 'Test',
    Output: null,
    Progress: null,
    Status: 1,
    Total: 1,
    Type: 1},
  {Name: 'Test',
    Output: null,
    Progress: null,
    Status: 2,
    Total: 1,
    Type: 1}
]

const duplicateKitItemMocks = [
  {Name: 'Kit item',
    Output: null,
    Progress: 1,
    Status: 1,
    Total: 3,
    Type: 2},
  {Name: 'Kit item',
    Output: null,
    Progress: 2,
    Status: 1,
    Total: 3,
    Type: 2},
  {Name: 'Kit item',
    Output: null,
    Progress: 3,
    Status: 1,
    Total: 3,
    Type: 2},
  {Name: 'Kit item',
    Output: null,
    Progress: 3,
    Status: 2,
    Total: 3,
    Type: 2}
]



const duplicateListMocks = [
  {Name: 'My list to check length',
    Output: null,
    Progress: 1,
    Status: 1,
    Total: 3,
    Type: 2},
  {Name: 'My list to check length',
    Output: null,
    Progress: 2,
    Status: 1,
    Total: 3,
    Type: 2},
  {Name: 'My list to check length',
    Output: null,
    Progress: 3,
    Status: 1,
    Total: 3,
    Type: 2},
  {Name: 'My list to check length',
    Output: {
      CompletedCount: 2,
    },
    Progress: 3,
    Status: 4,
    Total: 3,
    Type: 3}
]


let mockIndex = 0

/**
 * Enum values present async activity status
 * @enum {number}
 */
const ActivityStatus = {
  InProgress: 1,
  Completed: 2,
  Failed: 3,
  PartiallyFailed: 4,
};

/**
 * Enum values activity type
 * @enum {number}
 */
const ActivityType = {
  OrderItemDuplication: 1,
  KitOrderItemDupliction: 2,
  CartListDuplication: 3,
  OrderItemReordering: 4,
  KitOrderItemReordering: 5,
  OrderReordering: 6,
};

/**
 * Provides information about asynchronous activity.
 * @typedef ActivityModel
 * @property {GUID} ActivityID The unique ID of the activity
 * @property {ActivityStatus} Status The status of the activity
 * @property {ActivityType} Type Activity type
 * @property {string} Name The name of the activity
 * @property {number} Total The total number of sub-items of the activity
 * @property {number} Progress Activity progress
 * @property {null} Output The output of the activity
 */

/**
 * Collection of information about asynchronous activity.
 * @typedef {ActivityModel[]} ActivityModelArray
 */

/**
 * Provides active tasks of the current user
 * @method
 * @param {GUID[]} activitiesIDs Collection of globally unique identifier
 * @returns {Promise<ActivityModelArray>} Returns a collection of information about asynchronous activity.
 */
export const getActivities = async (activitiesIDs) => {
  // const mock = duplicateListMocks[mockIndex++]
  // mock.ActivityID = activitiesIDs[0]
  // return Promise.resolve(mock)
  const params = activitiesIDs.map(id => `activityIDs=${id}`).join('&')
  return await http.get(`${config.getValue('apiUrl')}/v1/store/login/user/activities?${params}`)
}
