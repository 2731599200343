class Context {
  constructor () {
    this.context = {}
  }

  set (obj) {
    this.context = Object.assign({}, this.context, obj)
  }

  getValue (key) {
    return this.context[key]
  }

  setValue (key, value) {
    this.context[key] = value
  }

  clear () {
    this.context = {}
  }

  get () {
    return this.context
  }
}

export default new Context()
