import Layout from '../components/layout'
import './AboutUs.scss'

const AboutUs = (props) => {
    return (
      <Layout {...props} className="about-us">
        <div className='main-content'>
            <h1 className='top-fix'>About Us</h1> {/* G: Fix for top banner */}
            <p>Are you looking for new ways to leverage printed products? Print It ZA is more than a simple flyer design service and book printer. We are a B2B and B2C printing company committed to our customers’ needs by being attentive, responsive, and flexible. Our experience makes us a trusted partner for major corporate companies, brand and marketing managers, print procurement divisions, and individuals. We are here to provide you with a tailor-made, high-quality printing service.</p>
<h2>Best printing company near me — Making any ideas into printed versions</h2>
<p>It’s in Print It ZA’s DNA to seek innovative printing methods and embrace trends. Based in Midrand, Johannesburg, we’ve earned a reputation as the #1 print shop in South Africa that has mastered litho, digital, and eco-friendly printing.</p>
<p>Thanks to the diversity of our know-how and equipment, we can print and liven up a plethora of pieces:</p>
<ul>
<li>magazines</li>
<li>books</li>
<li>booklets</li>
<li>flyers</li>
<li>brochures</li>
<li>reports</li>
<li>training manuals</li>
<li>business cards</li>
<li>study guides</li>
<li>and more</li>
</ul>
<p>If you’re here to find a print shop in Johannesburg that can do it all for your commercial, business, or personal needs, Print It ZA is happy to assist you. From design to printing and packaging, our in-house team is ready to help.</p>
<h2>Quality that you expect to get</h2>
<p>Whatever the scope of your printing project, we pay heed to every nuance of it. Whether you need help with printing a few blueprints or starting a complex print job, we can handle each order professionally and efficiently. Quality is prioritized along the way.</p>
<p>As a full-service 24-hour Johannesburg printing company, we are a one-stop place to get whatever you have in your creative mind printed. With Print It ZA, you always have an expert team on your side to:</p>
<ul>
<li>deliver printed products nationwide and export across the globe</li>
<li>meet your deadlines while ensuring the quality of our printing services</li>
<li>put all our expertise and skills into every piece we print</li>
<li>set a fair price for your order</li>
<li>advise you on print designs, templates, and folding options</li>
<li>ensure fast door-to-door delivery</li>
</ul>
<p>To do this, we’ve invested in solar panels and generators to keep our facilities up to date and running smoothly, regardless of the print job size. We also adopt innovative quality assurance practices before, during, and after your pieces are printed.</p>
<h2>Print shop near me — Print It ZA makes a difference</h2>
<p>No product leaves our premises without proper controls throughout the printing process. But that’s not the only thing that makes us different:</p>
<ul>
<li><strong>Custom printing options.</strong>&nbsp;Be the one to control your print runs and get the end result you want. You can choose from many paper, finishing, and color options.</li>
<li><strong>Eco-conscious mindset.</strong>&nbsp;We embrace eco-friendly printing processes to safeguard our planet.</li>
<li><strong>Prices you’ll love.</strong>&nbsp;Print It ZA helps you keep your expenses down for all print jobs. We also deliver for free if your printed materials are less than 90kg in weight.</li>
</ul>
<p>Our print shop in Johannesburg can turn any printing idea into reality. Want to check it out? Contact us at 010 446 5618.</p>


            <img className='image-width-full' src='https://onlineprintshop.remata.co.za/ustorethemes\OPSTheme2024\assets\images\Reception.jpg' alt='Remata Reception'></img>     
        </div>
      </Layout>
    )
}
  
export default AboutUs