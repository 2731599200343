import providerState from './providerState'
import { SET_CURRENT_CURRENCY, SET_CURRENT_CULTURE_BY_LANGUAGE } from '../redux/actionsTypes'

/**
 * globally unique identifier
 * @typedef {string} GUID
 */

/**
 * Provides information about a currency.
 * @typedef {Object} CurrencyModel
 * @property {GUID} ID The unique ID of the currency.
 * @property {number} FriendlyID The friendly ID of the currency.
 * @property {string} Name The name of the currency.
 * @property {string} Symbol The symbol of the currency.
 * @property {string} Code The currency code according to the ISO 4217 standard (e.g. "USD").
 * @property {number} ConversionRate The conversion rate from the store's primary currency to this currency. E.g. If the primary currency is USD, this currency is EUR and the conversion rate is 0.886285, then 1 USD = 0.886285 EUR.
 */

/**
 * Provides information about a product's price and tax.
 * @typedef {Object} ProductPriceModel
 * @property {number} Price The price of the product excluding tax, in the store's primary currency.
 * @property {number} Tax The tax of the product, in the store's primary currency.
 */

/**
 * Represents the culture logic in the system
 * @class
 */
class Culture {
  /**
   * Sets requested currency model
   * @param {CurrencyModel} currentCurrency - the requested currency to set as the current currency
   * @description Set **state.currentCurrency** with the requested currency model as {@link CurrencyModel}
   */
  setCurrentCurrency (currentCurrency) {
    providerState.dispatch({ type: SET_CURRENT_CURRENCY, data: currentCurrency })
  }

  /**
   * Sets the requested language code
   * @param {string} languageCode - the requested language code
   * @description Set **state.currentCurrency** according the the language code requested
   */
  setCurrentCultureByLanguage (languageCode) {
    providerState.dispatch({ type: SET_CURRENT_CULTURE_BY_LANGUAGE, data: languageCode })
  }

  /**
   * @typedef {Object} PriceModel
   * @property {number} price Price
   * @property {number} tax Tax
   * @property {number} priceIncludingTax priceIncludingTax that are converted by the current currency
   */

  /**
   * Get the converted price according to the current currency in the state
   * @param {ProductPriceModel} priceModel - the price model to be converted
   * @returns {PriceModel} - price, tax and priceIncludingTax that are converted by the current currency
   */
  getConvertedPrices (priceModel) {
    const { currentCurrency } = providerState.get()
    return {
      price: priceModel.Price * currentCurrency.ConversionRate,
      tax: priceModel.Tax * currentCurrency.ConversionRate,
      priceIncludingTax: (priceModel.Price + priceModel.Tax) * currentCurrency.ConversionRate
    }
  }
}

export default new Culture()
