import Layout from '../components/layout'
import './ContactUs.scss'



const ThankYou = (props) => {
   
    return (
      <Layout {...props} className="contact-us">
        <div className="container-fluid">
          
          <div className="row top-fix"> {/* G: Fix for top banner */}
            <div className="col-sm-5 contact-float-L"> {/* G: Float Application */}
            <h1>THANK YOU</h1> 
              <p>Thank you for your submission, we will be in touch.</p>
              <p>PRINT IT ZA<br /> 
              International Business Gateway Park<br />
              Cnr 6th and New Road, Midrand, Gauteng, South Africa</p>
              <p>Tel: <a href='tel:010 446 5618'>010 446 5618</a><br />
              Email: <a href='mailto:info@printitza.co.za'>info@printitza.co.za</a></p>
            </div>
          <div className='contact-float-R'> {/* G: Float Application + map insertion */}
            <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d229547.3351168587!2d27.81623509453125!3d-25.9786279!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e9513eabe8cf1bb%3A0xf22a7b4379fae5e4!2sPrint%20It%20ZA!5e0!3m2!1sen!2sza!4v1708504169313!5m2!1sen!2sza"
            width="100%"
            height={480}
            frameBorder={0}
            style={{ border: 0 }}
            allowFullScreen=""/>
          </div>
          </div>
        </div>
      </Layout>
    )
}
  
export default ThankYou