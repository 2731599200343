/**
 * globally unique identifier
 * @typedef {string} GUID
 */

/**
 * Product types.
 * @enum {number}
 */
const ProductType = {
  /** Dynamic product. */
  Dynamic: 1,
  /** Static product. */
  Static: 2,
  /** Email product */
  Email: 3,
  /** Uploaded product. */
  Uploaded: 4,
  /** Composite product. */
  Composite: 5,
  /** Dynamic product with PURL. */
  DynamicWithPurl: 6,
  /** Email product with PURL. */
  EmailWithPurl: 7,
  /** Circle project's product. */
  CircleProject: 8,
  /** Print touchpoint's product. */
  PrintTouchPoint: 9,
  /** Email touchpoint's product. */
  EmailTouchPoint: 10,
  /** Web touchpoint's product. */
  WebTouchPoint: 11,
  /** PDF on-demand touchpoint's product. */
  PdfOnDemandTouchPoint: 12,
  /** Triggered email touchpoint's product. */
  TriggeredEmailTouchPoint: 13,
  /** Kit product. */
  Kit: 14,
};

/**
 * Enum values present async activity status
 * @enum {number}
 */
const ActivityStatus = {
  InProgress: 1,
  Completed: 2,
  Failed: 3,
  PartiallyFailed: 4,
};

/**
 * Enum values present uProduce job output format.
 * @enum {number}
 */
const OutputFormat = {
  Undefined: 0,
  PDF: 1,
  JPG: 2,
};

/**
 * Provides information about a product inventory.
 * @typedef {Object} ProductInventoryModel
 * @property {number} Quantity The number of units available in the inventory.
 * @property {boolean} AllowOutOfStockPurchase Indicates whether ordering is allowed, despite the inventory being out-of-stock.
 */

/**
 * Provides information about a product unit type.
 * @typedef {Object} ProductUnitTypeModel
 * @property {string} Name The singular form of the product unit name (e.g. "Box", "Item", "Pack", "Piece").
 * @property {string} PluralName The plural form of the product unit name (e.g. "Boxes", "Items", "Packs", "Pieces").
 */

/**
 * Provides information about a product unit.<br/> The unit can be a single item, multiple items or a pack of items.
 * @typedef {Object} ProductUnitModel
 * @property {number} ItemQuantity The number of items that make up the unit.
 * @property {ProductUnitTypeModel} ItemType The type of item.
 * @property {ProductUnitTypeModel} PackType The type of pack. The value is undefined (equals null) for units that are not sold in packs.
 */

/**
 * Provides information about a product's price and tax.
 * @typedef {Object} ProductPriceModel
 * @property {number} Price The price of the product excluding tax, in the store's primary currency.
 * @property {number} Tax The tax of the product, in the store's primary currency.
 */

/**
 * Provides information about the SEO configuration of a category or product.
 * @typedef {Object} SeoConfigurationModel
 * @property {string} Title The title of a webpage.
 * @property {string} Description The description of a webpage.
 */

/**
 * Provides information about a product's quantity option.
 * @typedef {Object} ProductQuantityItemModel
 * @property {string} Name The quantity option name.
 * @property {number} Value The quantity option value.
 */

/**
 * Provides information about a product's quantity configuration.
 * @typedef {Object} ProductQuantityModel
 * @property {number} Minimum The minimum number of units that can be purchased.
 * @property {number} Maximum The maximum number of units that can be purchased.
 * @property {boolean} Changeable Indicates whether the shopper can select the number of units to be purchased.
 * @property {ProductQuantityItemModel[]} Options The list of the available quantities.
 */

/**
 * Provides information about a product's configuration.
 * @typedef {Object} ProductConfigurationModel
 * @property {boolean} AllowQuickAddToCart Indicates whether the shopper can quick add product to cart from list
 * @property {ProductQuantityModel} Quantity The configuration of the product's quantity.
 * @property {ProductQuantityModel[]} Properties The properties of the product.
 */

/**
 * Provides information about a product.
 * @typedef {Object} ProductModel
 * @property {GUID} ID The unique ID of the product.
 * @property {number} FriendlyID The friendly ID of the product.
 * @property {string} Name The name of the product.
 * @property {string} ShortDescription The short description of the product.
 * @property {string} Description The full description of the product.
 * @property {string} ExternalID The ID of the product in an external application.
 * @property {string} CatalogNumber The catalog number of the product. The value is undefined (equals null) if the user does not have permission to view the catalog number.
 * @property {boolean} HasPricing Indicates whether a price was defined for the product.
 * @property {string} ImageUrl The URL of the product image.
 * @property {string[]} Thumbnails The product thumbnails.
 * @property {ProductInventoryModel} Inventory The inventory of the product. The value is undefined (equals null) if the user does not have permission to view the product inventory.
 * @property {ProductUnitModel} Unit The unit of the product.
 * @property {number} MinimumQuantity The minimum number of units that can be purchased.
 * @property {ProductPriceModel} MinimumPrice The minimum price of the product. The value is undefined (equals null) if the user does not have permission to view the product price.
 * @property {ProductType} Type The type of the product.
 * @property {ProductConfigurationModel} Configuration The configuration of the product.
 */

/**
 * Provides information about a product, including additional data such as SEO configuration.
 * @typedef {Object} ProductDetailsModel
 * @property {SeoConfigurationModel} SeoConfiguration The SEO configuration.
 * @property {GUID} ID The unique ID of the product.
 * @property {number} FriendlyID The friendly ID of the product.
 * @property {string} Name The name of the product.
 * @property {string} ShortDescription The short description of the product.
 * @property {string} Description The full description of the product.
 * @property {string} ExternalID The ID of the product in an external application.
 * @property {string} CatalogNumber The catalog number of the product. The value is undefined (equals null) if the user does not have permission to view the catalog number.
 * @property {boolean} HasPricing Indicates whether a price was defined for the product.
 * @property {string} ImageUrl The URL of the product image.
 * @property {string[]} Thumbnails The product thumbnails.
 * @property {ProductInventoryModel} Inventory The inventory of the product. The value is undefined (equals null) if the user does not have permission to view the product inventory.
 * @property {ProductUnitModel} Unit The unit of the product.
 * @property {number} MinimumQuantity The minimum number of units that can be purchased.
 * @property {ProductPriceModel} MinimumPrice The minimum price of the product. The value is undefined (equals null) if the user does not have permission to view the product price.
 * @property {ProductType} Type The type of the product.
 * @property {ProductConfigurationModel} Configuration The configuration of the product.
 */

/**
 * Provides a list of products according to the pagination.
 * @typedef {Object} ProductListModel
 * @property {ProductModel[]} Products The list of the products according to the pagination.
 * @property {number} Count The total number of products without pagination.
 */

/**
 * Provides information about thumbnail.
 * @typedef {Object} ThumbnailModel
 * @property {string} Url The URL of the thumbnail.
 * @property {string} DisplayName The name of the thumbnail.
 */

/**
 * Provides information about thumbnails.
 * @typedef {Object} ThumbnailsModel
 * @property {ThumbnailModel[]} Thumbnails A thumbnail list representation.
 */

/**
 * Added/updated Dial Value
 * @typedef {Object} OrderItemPropertyModel
 * @property {string} ID The unique ID of the property.
 * @property {Object} Value The value of the property.
 */

/**
 * Request model with the data for the preview (fast-proof or immediate) uProduce job.
 * @typedef {Object} CreatePreviewRequestModel
 * @property {OrderItemPropertyModel[]} Properties Customization values
 */

/**
 * Provides DTO for preview files.
 * @typedef {Object} OutputItemModel
 * @property {string} Url Preview file URL.
 * @property {string} Title Localized title for the proof output file
 */

/**
 * Provides preview DTO.
 * @typedef {Object} PreviewResponseModel
 * @property {GUID} PreviewID Preview ID.
 * @property {ActivityStatus} Status Production status.
 * @property {OutputFormat} Format Preview format.
 * @property {OutputItemModel[]} Items Collection of the preview files.
 */

/**
 * Provides proof DTO.
 * @typedef {Object} ProofResponseModel
 * @property {GUID} ProofID Preview ID.
 * @property {ActivityStatus} Status Production status.
 * @property {OutputFormat} Format Preview format.
 * @property {OutputItemModel[]} Items Collection of the preview files.
 */

/**
 * Provides information about proof status
 * @typedef {Object} ProofStatusModel
 * @property {GUID} OrderItemID The unique ID of the order item
 * @property {ActivityStatus} Status The status of the proof
 * @property {string} ThumbnailUrl The thumbnail url of the proof
 */

export const ProductTypes = {}