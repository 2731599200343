import http from './http'
import config from '../services/config'

const {
  StoreModel,
  UserModel,
  CurrencyModel,
  CartIndicatorModel,
  SecurityTokenModel,
  CultureModel,
} = require('./../types/store.js')

/**
 * Gets the store to which the user is currently logged in. The store unique ID and the currently logged-in user are identified by the provided security token.
 * @method
 * @returns {Promise<StoreModel>} Returns information about a store to which the user currently logged in.
 */
export const getCurrentStore = async () => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store`)
}

/**
 * Gets the currently logged-in user, who is identified by the provided security token.
 * @method
 * @returns {Promise<UserModel>} Returns information about a user that is currently logged-in to the store
 */
export const getCurrentUser = async () => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/login/user`)
}

/**
 * Update or create preferences for user that is currentlly logged-in to the store.
 * @method
 * @param {string} value The string value.
 * @returns {Promise<boolean>} Returns information about a user that is currently logged-in to the store.
 */
export const updateUserPreferences = async (value) => {
  return await http.put(`${config.getValue('apiUrl')}/v1/store/login/user/preferences`, { value })
}

/**
 * A list of the store's cultures
 * @typedef {CultureModel[]} CultureModelArray
 */

/**
 * Gets the cultures of the store.
 * @method
 * @returns {Promise<CultureModel[]>} Returns a list of the store's cultures.
 */
export const getCultures = async () => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/cultures`, null)
  // Use this for mock data from cultures.json
  // return await http.get(`${config.getValue('baseUrl')}/config/stores/store-a/cultures.json`)
}

/**
 * A list of the store's currencies
 * @typedef {CurrencyModel[]} CurrencyModelArray
 */

/**
 * Gets the currencies of the store.
 * @method
 * @returns {Promise<CurrencyModelArray>} Returns a list of the store's currencies.
 */
export const getCurrencies = async () => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/currencies`)
  // Use this for mock data from currencies.json
  // return await http.get(`${config.getValue('baseUrl')}/config/stores/store-a/currencies.json`)
}

/**
 * Gets the information about the cart items count.
 * @method
 * @returns {Promise<CartIndicatorModel>} Returns the information about the cart items count.
 */
export const getCartItemsCount = async () => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/login/user/orders/cart/items/count`)
}

/**
 * Renews the user session.
 * @method
 * @returns {Promise<SecurityTokenModel>} Returns information about a security token.
 */
export const renewToken = async () => {
  return await http.post(`${config.getValue('apiUrl')}/v1/store/login/renew`)
}

/**
 * Logs the user into the store using temporary SSO token.
 * @method
 * @param {string} Token The SSO token
 * @returns {Promise<SecurityTokenModel>} Returns information about a security token.
 */
export const loginBySSOToken = async (Token) => {
  return await http.post(`/uStoreRestApi/v1/store/sso/login`, { Token })
}
