
/**
 * globally unique identifier
 * @typedef {string} GUID
 */

/**
 * Provides graphic asset DTO.
 * @typedef {Object} AssetListItemResponseModel
 * @property {string} ThumbnailUrl Graphic asset thumbnail URL.
 * @property {string} AssetID Graphic asset ID.
 * @property {string} DisplayName Graphic asset name.
 * @property {boolean} IsUploaded Flag that use to distinguish user uploaded asset from PE asset.
 * @property {string} FileName Graphic asset name with extension.
 * @property {Date} ModificationDate Graphic asset modification date.
 */

/**
 * Provides graphic asset DTO.
 * @typedef {Object} AssetResponseModel
 * @property {string} AssetID Graphic asset ID.
 * @property {string} DisplayName Graphic asset name.
 * @property {boolean} IsUploaded Flag that use to distinguish user uploaded asset from PE asset.
 * @property {string} FileName Graphic asset name with extension.
 * @property {string} Url Graphic asset URL to the original content.
 * @property {string} ThumbnailUrl Graphic asset thumbnail URL.
 * @property {number} Width Graphic asset width in pixels.
 * @property {number} Height Graphic asset height in pixels.
 * @property {number} SizeKB Graphic asset size in kilobyte.
 */

export const AssetsTypes = {}