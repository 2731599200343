import React, { useRef } from 'react'
import emailjs from '@emailjs/browser'
import Layout from '../components/layout'
import './ContactUs.scss'



const ContactUs = (props) => {
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('PrintItZA', 'template_811953h', form.current, 'sQT4wueLUi9FQEQJJ')
        .then((result) => {
            console.log(result.text);
            console.log("message sent");
           // alert('Message sent successfully! We will get back to you asap.')
           window.location.replace('https://shop.printitza.co.za/en-ZA/ThankYou/');
           
        }, (error) => {
            console.log(error.text);
            alert(`${error.text} happened`);
            document.getElementById(form.current).reset();
        });
    };
    return (
      <Layout {...props} className="contact-us">
        <div className="container-fluid">
          
          <div className="row top-fix"> {/* G: Fix for top banner */}
            <div className="col-sm-5 contact-float-L"> {/* G: Float Application */}
            <h1>CONTACT US</h1> 
              <p>To get in touch with us, please complete the form below and <br />
                we will get back to you asap.</p>
              <p>PRINT IT ZA<br /> 
              International Business Gateway Park<br />
              Cnr 6th and New Road, Midrand, Gauteng, South Africa</p>
              <p>Tel: <a href='tel:010 446 5618'>010 446 5618</a><br />
              Email: <a href='mailto:info@printitza.co.za'>info@printitza.co.za</a></p>
              <form ref={form} onSubmit={sendEmail}>
              <label>Name</label><br />
              <input className='field-width' type="text" name="user_name" required/><br /> {/* G: Input Styling */}
              <label>Email</label><br />
              <input className='field-width' type="email" name="user_email" required/><br /> {/* G: Input Styling */}
              <label>Telephone number</label><br />
              <input className='field-width' type="tel" name="user_tel" required/><br /> {/* G: Input Styling */}
              <label>Message</label><br />
              <textarea className='field-width' name="message" /><br /> {/* G: Input Styling */}
              <input type="submit" value="Send" /><br />
              </form>
            </div>
          
          <div className='contact-float-R'> {/* G: Float Application + map insertion */}
            <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d229547.3351168587!2d27.81623509453125!3d-25.9786279!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e9513eabe8cf1bb%3A0xf22a7b4379fae5e4!2sPrint%20It%20ZA!5e0!3m2!1sen!2sza!4v1708504169313!5m2!1sen!2sza"
            width="100%"
            height={480}
            frameBorder={0}
            style={{ border: 0 }}
            allowFullScreen=""/>
          </div>
        </div>
        </div>
      </Layout>
    )
}
  
export default ContactUs