export default {
  currentStore: null,
  currentUser: null,
  initState: true,
  customState: {},
  cultures: [],
  currentCulture: null,
  currencies: [],
  currentCurrency: null,
  assets: [],
}
