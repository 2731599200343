/**
 * globally unique identifier
 * @typedef {string} GUID
 */

/**
 * Provides information about a category of products.
 * @typedef {Object} CategoryModel
 * @property {GUID} ID The unique ID of the category.
 * @property {number} FriendlyID The friendly ID of the category.
 * @property {string} Name The name of the category.
 * @property {string} Description The description of the category.
 * @property {boolean} HasProducts Indicates whether the category contains at least one online product.
 * @property {boolean} HasSubCategories Indicates whether the category contains at least one sub-category, of any level, that has an online product.
 * @property {string} ImageUrl The URL of the category image.
 */

/**
 * Provides a list of product categories according to the pagination.
 * @typedef {Object} CategoryListModel
 * @property {CategoryModel[]} Categories The list of the product categories according to the pagination.
 * @property {number} Count The total number of product categories without pagination.
 */

/**
 * Provides information about the SEO configuration of a category or product.
 * @typedef {Object} SeoConfigurationModel
 * @property {string} Title The title of a webpage.
 * @property {string} Description The description of a webpage.
 */

/**
 * Provides information about a category, including additional data such as SEO configuration.
 * @typedef {Object} CategoryExtendedModel
 * @property {SeoConfigurationModel} SeoConfiguration The SEO configuration.
 * @property {GUID} ID The unique ID of the category.
 * @property {number} FriendlyID The friendly ID of the category.
 * @property {string} Name The name of the category.
 * @property {string} Description The description of the category.
 * @property {boolean} HasProducts Indicates whether the category contains at least one online product.
 * @property {boolean} HasSubCategories Indicates whether the category contains at least one sub-category, of any level, that has an online product.
 * @property {string} ImageUrl The URL of the category image.
 */

/**
 * Node representation in a product category tree.
 * @typedef {Object} CategoryTreeNodeModel
 * @property {CategoryModel} Category The product category of the node.
 * @property {CategoryTreeNodeModel[]} SubCategories The sub-categories (child nodes) of the node.
 */

/**
 * Node representation in a product category tree.
 * @typedef {Object} CategoryTreeModel
 * @property {CategoryTreeNodeModel} Categories The list of top level (root) categories.
 */

export const CategoriesTypes = {}
