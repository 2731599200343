import providerState from './providerState'
import { LOAD_CATEGORIES_TREE, LOAD_SUB_CATEGORIES, LOAD_CURRENT_CATEGORY } from '../redux/actionsTypes'
import {
  getCategoryIDByFriendlyID,
  getCategoryTree,
  getSubCategories,
  getCategory
} from '../api/categories'

const {
  CategoryTreeModel,
  CategoryModel,
  CategoryExtendedModel
} = require('./../types/categories.js')

/**
 * Represent the categories logic and API in the system
 * @class
 */

class Categories {
  /**
   * Gets the sub-categories of a specific product category, using pagination.
   * @param {number} [depth] - Indicates the maximum depth of the tree to return. By default (1), returns root categories only.
   * @returns {CategoryTreeModel} - The tree of categories
   * @description Set **state.categoriesTree** with the categories tree model as {@link CategoryTreeModel}
   * @description Set **state.categories** with the first level categories group.
   */
  async loadCategoriesTree (depth = 1) {
    const { Categories } = await getCategoryTree(depth)
    providerState.dispatch({ type: LOAD_CATEGORIES_TREE, data: Categories })
    return Categories
  }

  /**
   * Sets sub-categories of the given category.
   * @method
   * @param {string} categoryFriendlyID - the category friendly ID
   * @param {number} [pageNumber] - The 1-based number of the page.
   * @param {number} [pageSize] - The amount of the categories in one page.
   * @returns {CategoryModel[]} Array of sub categories in a specific page
   * @description Set **state.subCategories** with the sub-categories of the given category model as {@link CategoryModel} in the store, using paging.
   */
  async loadSubCategories (categoryFriendlyID, pageNumber = null, pageSize = null) {
    const categoryID = await getCategoryIDByFriendlyID(categoryFriendlyID)
    const { Categories } = await getSubCategories(categoryID, pageNumber, pageSize)
    providerState.dispatch({ type: LOAD_SUB_CATEGORIES, data: Categories })
    return Categories
  }

  /**
   * Set state.currentCategory with the requested category by friendlyID.
   * @param {string} categoryFriendlyID - the category friendly ID
   * @returns {CategoryExtendedModel} - A category model
   * @description Set state.currentCategory with the requested category by friendlyID using model {@link CategoryExtendedModel}
   */
  async loadCurrentCategory (categoryFriendlyID) {
    const categoryID = await getCategoryIDByFriendlyID(categoryFriendlyID)
    const currentCategory = await getCategory(categoryID)
    providerState.dispatch({ type: LOAD_CURRENT_CATEGORY, data: currentCategory })
    return currentCategory
  }
}

export default new Categories()
