import culture from './culture'
import store from './store'
import categories from './categories'
import products from './products'
import providerState from './providerState'
import assets from './assets'

export default {
  assets,
  culture,
  store,
  categories,
  products,
  get: () => providerState.get(),
  set: (state) => providerState.set(state),
  dispatch: providerState.dispatch,
  subscribe: providerState.subscribe,
  customState: {
    set: (name, value) => providerState.setCustomState(name, value),
    setBulk: (obj) => providerState.setBulkCustomState(obj),
    get: (name) => name ? providerState.get().customState[name] : providerState.get().customState,
    delete: (name) => providerState.deleteCustomState(name),
    merge: (customState) => providerState.mergeCustomState(customState)
  }
}
