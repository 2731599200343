import { initStore } from '../redux/store'
import { CLEAR_STATE, SET_CUSTOM_VAR, DELETE_CUSTOM_VAR, MERGE_CUSTOM_STATE } from '../redux/actionsTypes'

/**
 * Represents the state management for the provider.
 * This class provides methods to interact with the state store.
 * @class
 */
class ProviderState {
  /**
   * Constructs a new ProviderState instance.
   * Initializes the state store.
   */
  constructor () {
    this.store = initStore()
  }

  /**
   * Retrieves the current state from the store.
   * @returns {Object} The current state object.
   */
  get () {
    return this.store.getState()
  }

  /**
   * Sets the state in the store.
   * @param {Object} state - The new state to set.
   */
  set (state) {
    this.store.dispatch({ type: '@@INIT_STATE', data: state })
  }

  /**
   * Sets the initial state in the store.
   */
  setInitState () {
    this.store.dispatch({ type: '@@INIT_STATE' })
  }

  /**
   * Retrieves the dispatch function from the store.
   * @returns {Function} The dispatch function.
   */
  get dispatch () {
    return this.store.dispatch
  }

  /**
   * Retrieves the subscribe function from the store.
   * @returns {Function} The subscribe function.
   */
  get subscribe () {
    return this.store.subscribe
  }

  /**
   * Sets a custom state variable in the store.
   * @param {string} name - The name of the custom variable.
   * @param {*} value - The value to set for the custom variable.
   */
  setCustomState (name, value) {
    this.store.dispatch({ type: SET_CUSTOM_VAR, data: { [name]: value } })
  }

  /**
   * Sets multiple custom state variables in the store.
   * @param {Object} obj - An object containing key-value pairs of custom variable names and values.
   */
  setBulkCustomState (obj) {
    this.store.dispatch({ type: SET_CUSTOM_VAR, data: obj })
  }

  /**
   * Deletes a custom state variable from the store.
   * @param {string} name - The name of the custom variable to delete.
   */
  deleteCustomState (name) {
    this.store.dispatch({ type: DELETE_CUSTOM_VAR, data: { name } })
  }

  /**
   * Merges new state into the custom state in the store.
   * @param {Object} newState - The new state object to merge.
   */
  mergeCustomState (newState) {
    this.store.dispatch({ type: MERGE_CUSTOM_STATE, data: newState })
  }

  /**
   * Clears the state in the store.
   */
  clear () {
    this.store.dispatch({ type: CLEAR_STATE, data: {} })
  }
}

export default new ProviderState()
