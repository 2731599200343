import React, { useRef } from 'react'
import Layout from '../components/layout'
import './Policy.scss'

const PolicyPrivacy = (props) => {
    return (
      <Layout {...props} className="policy">
        <div className='main-content'>
            <h1 className='top-fix'>Privacy Policy for Print It ZA</h1>
                <p>Last updated:  September 2021</p>
            <h3>Introduction and Application</h3>
                <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use Our Services and tells You 
                about Your privacy rights and how the law protects You.</p>
                <p>This Privacy Policy applies to you when you visit Our Websites, use our social media platforms, or make use of our Services as a customer or in respect of 
                Personal Information from suppliers/service providers and/or from individuals in relation to recruitment or if you are an employee of the Company.</p>
                <p>You agree to the collection and use of Your information in accordance with this Privacy Policy.</p>
            <h3>Interpretation and Definitions</h3>
                <h4>Interpretation</h4>
                    <p>Capitalised words have the meanings defined below. regardless of whether they appear in the singular or in the plural.</p>
                <h4>Definitions</h4>
                    <p>For the purposes of this Privacy Policy:</p>
                    <p><strong>• Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Policy) refers to Print It ZA Limited 
                    (including any of its subsidiary or group companies from time to time), with registered address situated at International Business Gateway Park, Corner 6th and New Road, Midrand, Gauteng.</p>
                    <p><strong>• Cookies</strong> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that Websites 
                    among its many users.</p>
                    <p><strong>• Country</strong> means a reference to South Africa.</p>
                    <p><strong>• Device</strong> means any device that can access the Services such as a computer, a cellphone or a digital tablet.</p>
                    <p><strong>• Personal Information</strong> means information relating to an identifiable, living, natural person, and where it is applicable, an identifiable, existing juristic person, as 
                    defined in terms of the POPI Act, including, but not limited to:<br />
                    » information relating to the race, gender, sex, pregnancy, marital status, national, ethnic or social origin, colour, sexual orientation, age, physical or mental health, well-being, disability, 
                    religion, conscience, belief, culture, language and birth of the person;<br />
                    » information relating to the education or the medical, financial, criminal or employment history of the person;<br />
                    » any identifying number, symbol, e-mail address, physical address, telephone number, location information, online identifier or other particular assignment to the person; the biometric 
                    information of the person (‘<strong>biometrics</strong>’ means a technique of personal identification that is based on physical, physiological or behavioural characterisation including blood typing, 
                    fingerprinting, DNA analysis, retinal scanning and voice recognition);<br />
                    » the personal opinions, views or preferences of the person;<br />
                    » correspondence sent by the person that is implicitly or explicitly of a private or confidential nature or further correspondence that would reveal the contents of the original correspondence;<br />
                    » the views or opinions of another individual about the person; and<br />
                    » the name of the person if it appears with other personal information relating to the person or if the disclosure of the name itself would reveal information about the person.<br />
                    </p>
                    <p><strong>• POPI Act means the Protection of Personal Information Act (Act 4 of 2013).</strong></p>
                    <p><strong>• Processing</strong> means any operation or activity or any set of operations, whether or not by automatic means, concerning Personal Information, including (a) the collection, 
                    receipt, recording, organisation, collation, storage, updating or modification, retrieval, alteration, consultation or use; (b) dissemination by means of transmission, distribution or making 
                    available in any other form; or (c) merging, linking, as well as restriction, degradation, erasure or destruction of information.</p>
                    <p><strong>• Services</strong> refers to the Services provided by the Company to You, being, amongst others, digital marketing strategies, lithographic printing, digital printing, variable data 
                    printing, web2print, cross-channel marketing, design and layout, point of sale displays, printing solutions, a web-based online printing service and products and all related services whether 
                    through a written, or verbal agreement and/or the access to or use of the Websites and/or any of Our social media platforms.</p>
                    <p><strong>• Special Personal Information</strong> means personal information concerning (a) the religious or philosophical beliefs, race or ethnic origin, trade union membership, political 
                    persuasion, health or sex life or biometric information of a person or, if applicable, entity or (b) the criminal behaviour of a person to the extent that such information relates to either the 
                    alleged commission by such person of any offence or any proceedings in respect of any offence allegedly committed by the person subject or the disposal of such proceedings.</p>
                    <p><strong>• Website(s)</strong> refers to the Print It ZA’s websites, accessible from https://www.printitza.co.za / https://printitza.co.za/shop/</p>
                    <p><strong>• You</strong> means the individual accessing or using the Services, or if applicable Your parent or legal guardian, or the company, or other legal entity on behalf of which such 
                    individual is accessing or using the Service, as applicable, and includes a reference to suppliers/service providers and/or recruitment candidates.</p>
            <h3>Collecting and Using your Information</h3>
                <h4>Collection of and Type of Data Collected</h4>
                    <p>Should you:<br />
                    • use Our Services,<br />
                    • act as Our service provider or<br />
                    • apply for a position with the Company,<br />
                    • be an employee of the Company.</p>
                    <p>We may ask You to provide Us with certain Personal Information or You may, of your own accord, provide Us with Personal Information.</p>
                    <p>We may also collect Personal Information about You:<br />
                    • directly from You;<br />
                    • in the course of any agreement that we may have concluded with You;<br />
                    • in the course of providing Services to You;<br />
                    • from publically available information;<br />
                    • when You visit/or interact with Our Websites or any of Our social media platforms;<br />
                    • when You visit Our offices;<br />
                    • when You communicate with Us via email or other means;<br />
                    • from third parties who are authorised to provide Us with Your Personal Information.</p>
                    <p>The Personal Information collected from You or which relates to You, may include, but is not limited to:<br />
                    First name and last name, title, phone number, address, email address, photograph, biometric information, identifying number, location information, online 
                    identifier, demographic information, gender, date of birth, Identity Number, Passport Number, nationality, language preferences,, bank statements, agreements 
                    with third parties, employee or employee details, records of any interactions with Us, billing and payment details such as bank account numbers, data relating 
                    to visits to Our Websites and social media platforms, device types which accesses our Websites and social media platforms, IP address, location details and other 
                    technical communications information, car registration details.</p>
                    <p>To the extent that We need to Process any of Your Special Information, We shall only do so to for a legitimate purpose, in accordance with applicable law.</p>
                <h4>Tracking Technologies and Cookies</h4>
                    <p>We use Cookies and similar tracking technologies to track activity in respect of the use of  Our Services on Our Websites and We store certain information. 
                        Cookie and tracking technology are useful for gathering information such as browser type and operating system, tracking the number of visitors to Our Website, 
                        and understanding how visitors use Our Websites. Cookies can also help customize Our Websites for visitors. Personal information cannot be collected via 
                        cookies and other tracking technology; however, if you previously provided personally identifiable information, cookies may be tied to such information. 
                        Aggregate cookie and tracking information may be shared with third parties. Tracking technologies used are beacons, tags, and scripts to collect and track 
                        information and to improve and analyse Our Services. The technologies We use may include:<br />
                        <strong>• Cookies or Browser Cookies.</strong> A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to 
                        indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of Our Services. Unless You have adjusted 
                        Your browser setting so that it will refuse Cookies, then you accept that Our Service may use Cookies.<br />
                        <strong>• Flash Cookies.</strong> Certain features of our Service may use local stored objects (or Flash Cookies) to collect and store information about 
                        Your preferences or Your activity in relation to our Our Services. Flash Cookies are not managed by the same browser settings as those used for Browser 
                        Cookies. For more information on how You can delete Flash Cookies, please read "Where can I change the settings for disabling, or deleting local shared 
                        objects?" available at https://helpx.adobe.com/flash-player/kb/disable-local-shared-objectsflash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_<br />
                        <strong>• Web Beacons.</strong> Certain sections of our Services and our emails may contain small electronic files known as web beacons (also referred 
                        to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email 
                        and for other related Websites statistics (for example, recording the popularity of a certain section and verifying system and server integrity).</p>
                        <p>Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session 
                        Cookies are deleted as soon as You close Your web browser.</p>
                        <p>We may use both Session and Persistent Cookies for the purposes set out below:<br />
                        <strong>• Necessary / Essential Cookies</strong><br />
                        Type: Session Cookies<br />
                        Administered by: Us or Our Service Providers<br />
                        Purpose: These Cookies are essential to provide You with Services available through the Websites and to enable You to use some of its features. They help 
                        to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the Services that You have asked for cannot be provided, and We 
                        only use these Cookies to provide You with those Services.<br />
                        <strong>• Cookies Policy / Notice Acceptance Cookies</strong><br />
                        Type: Persistent Cookies<br />
                        Administered by: Us or Our Service Providers<br />
                        Purpose: These Cookies identify if users have accepted the use of cookies on the Website.<br />
                        <strong>• Functionality Cookies</strong><br />
                        Type: Persistent Cookies<br />
                        Administered by: Us or our Service Providers<br />
                        Purpose: These Cookies allow us to remember the choices You make when You use the Website, such as remembering Your login details or language preference. 
                        The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the 
                        Website.</p>
            <h3>Purposes of Processing and Legal bases for Processing</h3>
                <p>We will Process Your Personal Information:</p>
                <p>
                <strong>• </strong>in the ordinary course of Our business in relation to providing the Services and Products;<br />
                <strong>• </strong>to ensure that:<br />
                » You can provide services to Us and that We can comply with Our obligations to You as a supplier;<br />
                » You comply with Your obligations to Us as a supplier;<br />
                » We can employ You or comply with Our obligations to You as an employee; or<br />
                » You as an employee comply with Your obligations to Us as an employer.  We will use Your Personal Information only for the purpose for which it was originally 
                or primarily collected.  We may use Your Personal Information for a further purpose only if such purpose constitutes a legitimate interest and is closely related 
                to the original or primary purpose for which Your Personal Information was collected.  Your Personal Information may be Processed for, without limitation, the 
                following:<br />
                <strong>• </strong>to provide and maintain the Services and/or Products, including to monitor the usage of Our Services and/or Products and to contact You in 
                relation to the Services and or Products, to process payment for the Services and/or Products as well as to Inform You about similar Services and/or Products that 
                We provide (You may choose to stop receiving communications about similar Services and Products by contacting Us or by unsubscribing);<br />
                <strong>• </strong>to enter into or perform in terms of an agreement to which You are a party;<br />
                <strong>• </strong>to comply with any legislation, regulation, policy and/or directive in relation to the Company, including fraud prevention;<br />
                <strong>• </strong>to protect Your legitimate interests;<br />
                <strong>• </strong>to pursue a legitimate interest of the Company or a third party to whom the Personal Information is supplied;<br />
                <strong>• </strong>transfer  to or share with Our employees, service providers, agents, other companies within Our group and other third parties for the purposes 
                as set out in this Policy;<br />
                <strong>• </strong>to provide Youwith news, special offers and general information about other services which We offer that are similar to those that you have 
                already purchased/made use of or enquired about unless You have opted not to receive such information;<br />
                <strong>• </strong>to attend to and manage Your requests/instructions to Us;<br />
                <strong>• </strong>to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, 
                whether as a going concern or as part of liquidation, business rescue or similar proceedings, in which Personal Information held by Us is among the assets transferred;<br />
                <strong>• </strong>for other purposes: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness 
                of Our promotional campaigns and to evaluate and improve Our Services.  We may also disclose Your Personal Information for any other purpose with Your consent.</p>
            <h3>Retention and Storage of your Personal Information and your Rights</h3>
                <p>The Company will retain Your Personal Information only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your 
                    Personal Information to the extent necessary to comply with Our legal obligations (for example, if we are required to retain Your Personal Information to comply 
                    with applicable laws), resolve disputes, and enforce Our legal agreements and policies.</p>
                <p>You have the following Rights in relation to Your Personal Information the right to:<br />
                <strong>• </strong>upon having provided adequate proof of identification, request the Company to confirm, free of charge, whether or not the Company holds Personal 
                Information about You;<br />
                <strong>• </strong>request the record or a description of the Your Personal Information (including information about the identity of third parties or categories 
                of third parties who have or have had access to Your Personal Information), at a prescribed fee, within a reasonable time, in a reasonable manner and format, 
                and in a form that is generally understandable;<br />
                <strong>• </strong>in the prescribed manner, request the correction of Your Personal Information;<br />
                <strong>• </strong>in the prescribed manner, request the destruction or deletion of Your Personal Information, subject thereto that the Company is no longer 
                entitled/required to retain Your Personal Information;<br />
                <strong>• </strong>object to the processing of Your Personal Information, at which time this consent will be retracted. Such retraction will not affect the previous 
                collection and/or processing of Your Personal Information up until the retraction of the consent and/or Processing which does not per se require consent;<br />
                <strong>• </strong>lodge a complaint to the Information Regulator, in the prescribed manner and form.  The details of the Information Regulator are as follows:<br />
                Information Regulator (South Africa)<br />
                Address: JD House, 24 Stiemens Street, Braamfontein, Johannesburg;<br />
                Email:  POPIAcomplaints.IR@justice.gov.za</p>
                <p>We will use Our best endeavours to keep Your Personal Information that we collect accurate, complete and up to date.</p>
                <p>You can exercise Your Rights by contacting the Information Officer at the email address provided for the Information Officer in this Policy.</p>
            <h3>Transfer of your Information</h3>
                <p>Your information, including Personal Information may be subject to cross border transfer (i.e. transfer outside of the Country) and processing and/or retention 
                    by third parties in countries that have data laws, regulations and/or legislation that offers similar protection in respect of personal information as the POPI 
                    Act or to countries which offer less protection.  By acceptance of this Policy You consent to such transfer.</p>
                <p>
                <strong>• </strong>unique identifier information (examples of ‘unique identifiers’ as identified in terms of the POPI Act are <em>“Bank Account Numbers or any account 
                number; Policy Number; Identity Number; Employee Number; Student Number; Telephone or cell phone number; or Reference Number”</em>), then where this information: is to 
                be used for a purpose other than the purpose for which the unique identifier information was specifically intended (at the time of collection) and it is linked 
                with information processed by another, or other responsible parties; <br />
                <strong>• </strong>Special Personal Information or<br />
                <strong>• </strong>Personal Information of children (persons below the age of 18) to a third party in a foreign country that does not offer an adequate level of 
                protection similar to the POPI Act,</p>
                <p>then the Company will seek the prior authorisation from the Information Regulator before such transfer.  The Company will only have to obtain the authorisation 
                    from the Information Regulator once and not every time the Personal Information is received or processed (unless the processing differs from what was previously 
                    authorised).</p>
            <h3>Security of Personal Information</h3>
                <p>The security of Your Personal Information is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 
                    100% secure. While We strive to use commercially acceptable means to protect Your Personal Information, We cannot guarantee its absolute security.</p>
                <p>We will ensure that Our hosting service provider(s) will, in hosting Our server(s), Websites and other applications, use firewalls and other applicable security 
                    measures to prevent interference and unauthorised access to Your Personal Information.</p>
                <p>Authorisations to access Your Personal Information will be limited to those who are required to access such Personal Information for the purposes as set out in 
                    this Policy.</p>
            <h3>Links to other Websites</h3>
                <p>Our Services may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. 
                    We strongly advise You to review the Privacy Policy of every site You visit.</p>
                <p>We have no control over, and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
            <h3>Changes to this Privacy Policy</h3>
                <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on Our Websites and social media platforms 
                    and by way of communication, whether by email or otherwise of the new policy to You. You are advised to review this Privacy Policy periodically for any changes. 
                    Changes to this Privacy Policy are effective when they are posted on our Websites and/or provided to You.</p>
            <h3>Contact Us</h3>
                <p>If you have any questions about this Privacy Policy, You can contact us:<br />
                <strong>• </strong>By email: <a href='mailto:lance@printitza.co.za'>lance@printitza.co.za</a><br />
                <strong>• </strong>By phone number: +27 (0)10 446 5618</p>
            <h3>Details of Information Officer and/or Deputy information Officer</h3>
                <p>The details of the Company’s information Officer(s) and/or Deputy Information Officer(s) are set out below:<br />
                <strong>• </strong>NAME: Lance Sadie<br />
                <strong>• </strong>CONTACT DETAILS: +27 (0)10 446 5618 and <a href='mailto:lance@printitza.co.za'>lance@printitza.co.za</a></p>
        </div>
      </Layout>
    )
}
  
export default PolicyPrivacy