import http from './http'
import config from '../services/config'
import { convertObjectToQueryString } from '../utils/utils'

const {
  GUID,
  ProductListModel,
  ProductModel,
  ThumbnailsModel,
  CreatePreviewRequestModel,
  PreviewResponseModel,
  ProofResponseModel,
  ProofStatusModel,
 } = require('./../types/products.js')

/**
 * Gets the product's unique ID by its friendly ID.
 * @method
 * @param {number} friendlyID The friendly ID of the product.
 * @returns {Promise<GUID>} Returns a unique ID for the requested product.
 */
export const getProductIDByFriendlyID = async (friendlyID) => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/products/id?friendlyID=${friendlyID}`)
}

/**
 * Gets the online products of a specific product category, using pagination.
 * @method
 * @param {GUID} categoryID The unique ID of the parent category.
 * @param {number} pageNumber The requested page number.
 * @param {number} pageSize The number of products on a single page. The maximum is 200.
 * @returns {Promise<ProductListModel>} Returns a list of online products according the requested parameters.
 */
export const getProducts = async (categoryID, pageNumber, pageSize) => {
  const params = convertObjectToQueryString({ pageNumber, pageSize })
  return await http.get(`${config.getValue('apiUrl')}/v1/store/categories/${categoryID}/products${params.length ? `?${params}` : ''}`)
}

/**
 * A list of the products
 * @typedef {ProductModel[]} ProductModelArray
 */

/**
 * Gets the products by their unique IDs.
 * @method
 * @param {GUID[]} IDs The list of unique IDs of the products.
 * @returns {Promise<ProductModelArray>} Returns a list of the requested products.
 */
export const getProductsByIDs = async (IDs) => {
  const sIDs = IDs.map((val, ind) => `productIDs[${ind}]=${val}`).join('&')
  return await http.get(`${config.getValue('apiUrl')}/v1/store/products?${sIDs}`)
}

/**
 * Gets the product by its unique ID.
 * @method
 * @param {GUID} productID The unique ID of the product.
 * @param {boolean} retrieveTypeSpecificData Indicates if the model should be basic, or with specific data. For instance, kitting has the Items property, which is unique to this product type.
 * @returns {Promise<void>}
 */
export const getProductByID = async (productID, retrieveTypeSpecificData) => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/products/${productID}?retrieveTypeSpecificData=${retrieveTypeSpecificData}`)
}

/**
 * Gets product thumbnails.
 * @method
 * @param {GUID} productID The unique ID of the product.
 * @returns {Promise<ThumbnailsModel>} Returns a product thumbnails.
 */
export const getProductThumbnails = async (productID) => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/products/${productID}/thumbnails`)
}

/**
 * Creates preview for the dynamic product by order product ID.
 * @method
 * @param {GUID} orderItemID order product ID
 * @param {CreatePreviewRequestModel} properties Customization values
 * @returns {Promise<PreviewResponseModel>} Returns DTO with created preview details.
 */
export const createProofPreview = async (orderItemID, properties) => {
  return await http.post(`${config.getValue('apiUrl')}/v1/store/login/user/orders/unsubmitted/items/${orderItemID}/preview`, {properties})
}

/**
 * Gets preview status.
 * @method
 * @param {GUID} orderItemID order product ID
 * @param {GUID} previewID preview ID
 * @returns {Promise<PreviewResponseModel>} Returns DTO about preview.
 */
export const getProofPreview = async (orderItemID, previewID) => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/login/user/orders/unsubmitted/items/${orderItemID}/preview/${previewID}`)
}

/**
 * Returns binary content of the preview file by file name.
 * @method
 * @param {GUID} orderItemID order product ID
 * @param {GUID} previewID preview ID
 * @param {string} fileName preview file name
 * @returns {Promise<Buffer>} Returns binary content of the preview file.
 */
export const downloadProofPreview = async (orderItemID, previewID, fileName) => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/login/user/orders/unsubmitted/items/${orderItemID}/preview/${previewID}/download?fileName=${fileName}`, undefined, { download: true })
}

/**
 * Creates proof for the dynamic product by order product ID.
 * @method
 * @param {GUID} orderItemID order product ID
 * @returns {Promise<ProofResponseModel>} Returns DTO with created proof details.
 */
export const createProof = async (orderItemID) => {
  return await http.post(`${config.getValue('apiUrl')}/v1/store/login/user/orders/unsubmitted/items/${orderItemID}/proof`, {})
}

/**
 * Returns binary content of the proof file by file name.
 * @method
 * @param {GUID} orderItemID order product ID
 * @param {string} fileName proof file name
 * @returns {Promise<Buffer>} Returns binary content of the proof  file.
 */
export const downloadProof = async (orderItemID, fileName) => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/login/user/orders/unsubmitted/items/${orderItemID}/proof/download?fileName=${fileName}`, undefined, { download: true })
}

/**
 * Gets the products using a search string.
 * @method
 * @param {string} search The search string.
 * @param {number} pageNumber The requested page number.
 * @param {number} pageSize The number of products on a single page. The maximum is 200.
 * @returns {Promise<ProductListModel>} Returns a list of online products according the requested parameters.
 */
export const search = async (search, pageNumber, pageSize) => {
  const params = convertObjectToQueryString({ search, pageNumber, pageSize })
  return await http.get(`${config.getValue('apiUrl')}/v1/store/products/search${params.length ? `?${params}` : ''}`)
}

/**
 * A collection of information about proof status.
 * @typedef {ProofStatusModel[]} ProofStatusModelArray
 */

/**
 * Gets information about proof status
 * @method
 * @param {GUID[]} orderItemIds The collections os orders.
 * @returns {Promise<ProofStatusModelArray>} Returns a collection of information about proof status.
 */
export const checkProofStatus = async (orderItemIds) => {
  const params = orderItemIds.map(id => `orderItemIds=${id}`).join('&')
  return await http.get(`${config.getValue('apiUrl')}/v1/store/login/user/orders/unsubmitted/items/proof${params.length ? `?${params}` : ''}`)
}

export const productType = {
  Undefined: -1,
  Dynamic: 1,
  Static: 2,
  Email: 3,
  Uploaded: 4,
  Composite: 5,
  DynamicWithPurl: 6,
  EmailWithPurl: 7,
  CircleProject: 8,
  PrintTouchPoint: 9,
  EmailTouchPoint: 10,
  WebTouchPoint: 11,
  PdfOnDemandTouchPoint: 12,
  TriggeredEmailTouchPoint: 13,
  Kit: 14
}
