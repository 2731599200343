import providerState from './providerState'
import { LOAD_USER_ORDERS_SUMMARY, LOAD_CART_ITEMS_COUNT } from '../redux/actionsTypes'
import { getUserOrdersSummary } from '../api/orders'
import {getCartItemsCount}  from '../api/store'

/**
 * Provides information about the cart items count.
 * @typedef {Object} CartIndicatorModel
 * @property {number} ItemsCount The total number of items in the cart.
 */


/**
 * Provides a user's summary of orders.
 * @typedef {Object} UserOrdersSummaryModel
 * @property {number} RejectedOrderCount The number of rejected orders of the user.
 * @property {number} PendingApprovalOrderCount The number of orders pending for approval by the user.
 */

/**
 * Represent the orders logic and API in the system
 * @class
 */

class Orders {
  /**
   * Sets the summary of orders of the currently logged-in user.
   * @returns {UserOrdersSummaryModel|null} - if the store require order approval then the summary else null
   * @description Set **state.userOrdersSummary** with the summary of the orders of the currently logged in user.
   */
  async loadUserOrdersSummary () {
    const state = providerState.get()
    const requireOrderApproval = state.currentStore && state.currentStore.RequireOrderApproval
    if (requireOrderApproval) {
      const userOrdersSummary = await getUserOrdersSummary()
      providerState.dispatch({ type: LOAD_USER_ORDERS_SUMMARY, data: userOrdersSummary })
      return userOrdersSummary
    }
    return null
  }

  /**
   * Sets information about the cart items count.
   * @returns {CartIndicatorModel} Returns the cart items count.
   * @description Set **state.cartItemsCount** information about the cart items count.
   */
  async loadCartItemsCount () {
    const cartItemsCount = await getCartItemsCount()
    providerState.dispatch({ type: LOAD_CART_ITEMS_COUNT, data: cartItemsCount })
    return cartItemsCount
  }
}

export default new Orders()
