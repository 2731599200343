import http from './http'
import config from '../services/config'
import httpUpload from './httpUpload'

const {
  AssetListItemResponseModel,
  AssetResponseModel,
  GUID,
 } = require('./../types/assets.js')

/**
 * Collection of the assets.
 * @typedef {AssetListItemResponseModel[]} AssetListItemResponseModelArray
 */

/**
 * Gets collection of the graphic assets by dial ID.
 * @method
 * @param {GUID} propertyID Graphic dial ID
 * @returns {Promise<AssetListItemResponseModelArray>} Returns collection of the assets.
 */
export const getAssets = async (propertyID) => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/login/user/assets/?propertyID=${propertyID}`)
}

/**
 * Gets graphic asset detailed data by asset ID.
 * @method
 * @param {string} assetID Graphic asset ID.
 * @returns {Promise<AssetResponseModel>} Returns graphic assets detailed info.
 */
export const getAsset = async (assetID) => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/login/user/assets/${assetID}`)
}

/**
 * Deletes uploaded graphic asset by ID.
 * @method
 * @param {GUID} assetID Graphic asset ID.
 * @returns {string} Returns deleted graphic asset ID.
 */
export const deleteAsset = async (assetID) => {
  await http.delete(`${config.getValue('apiUrl')}/v1/store/login/user/assets/${assetID}`)
  return assetID
}

/**
 * Uploads graphic asset.
 * @method
 * @param {GUID} propertyID Graphic dial ID
 * @param {File} data File to upload
 * @param {boolean} replaceIfExists When value 'true' and dial has asset with the same name the asset should be replaced by uploaded file
 * @returns {AssetListItemResponseModel} Returns uploaded asset.
 */
export const uploadAsset = async (propertyID, data, replaceIfExists = false) => {
  const r = await httpUpload(`${config.getValue('apiUrl')}/v1/store/login/user/assets/?propertyID=${propertyID}${replaceIfExists ? '&replaceIfExists=true' : ''}`, [data])
  return r[0]
}
