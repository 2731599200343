import React from 'react'
import './ErrorBalloon.scss'
import { Icon } from '$core-components'

const ErrorBalloon = ({ children, className }) => {
  return <div className={`error-balloon ${className}`}>
    <div className="error-balloon-container">
      <Icon name="error.svg" width="13px" height="13px"/>
      <div className="error-balloon-children-container">
      {children}
      </div>
    </div>
  </div>
}

export default ErrorBalloon
