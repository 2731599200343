import http from './http'
import httpUpload from './httpUpload'
import httpDeleteFiles from './httpDeleteFiles'
import config from '../services/config'

const {
  GUID,
  CartModel,
  CartListModel,
  CartItemModel,
  OrderItemModel,
  CartPriceModel,
  CartItemUpdateModel,
  OrderItemPriceModel,
  CartListDetailsModel,
  UserOrdersSummaryModel,
  CartItemValidationModel,
  WishListItemUpdateModel,
  CartListCreateResponseModel,
  ActivityCreateResponseModel,
  OrderItemFileAttachmentPropertyModel,
 } = require('./../types/orders.js')

/**
 * Gets the summary of orders of the currently logged-in user, who is identified by the provided security token.
 * @returns {Promise<UserOrdersSummaryModel>} The summary of the orders of the currently logged in user.
 */
export const getUserOrdersSummary = async () => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/login/user/orders/summary`)
}

/**
 * Creates a draft order item of a product.
 * @method
 * @param {string} productID The unique ID of the product.
 * @returns {Promise<OrderItemModel>} Returns the created order item.
 */
export const addOrderItem = async (productID) => {
  return await http.post(`${config.getValue('apiUrl')}/v1/store/login/user/orders/draft/items?productID=${productID}`)
}

/**
 * Gets the order item that has not been submitted by its unique ID.
 * @method
 * @param {GUID} orderItemID The unique ID of the order item.
 * @returns {Promise<OrderItemModel>} Returns the requested order item.
 */
export const getOrderItem = async (orderItemID) => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/login/user/orders/unsubmitted/items/${orderItemID}`)
}

/**
 * Updates the order item that has not been submitted.
 * @method
 * @param {GUID} orderItemID The unique ID of the order item.
 * @param {OrderItemModel} orderItem The order item model.
 * @returns {Promise<OrderItemModel>} Returns the requested order item.
 */
export const updateOrderItem = async (orderItemID, orderItem) => {
  return await http.put(`${config.getValue('apiUrl')}/v1/store/login/user/orders/unsubmitted/items/${orderItemID}`, orderItem)
}

/**
 * Gets the price of the order item that has not been submitted.
 * @method
 * @param {string} orderItemID The unique ID of the order item.
 * @param {OrderItemModel} orderItem Provides information about an order item.
 * @returns {Promise<OrderItemPriceModel>} Returns the price of the provided order item model.
 */
export const getPriceOrderItem = async (orderItemID, orderItem) => {
  return await http.post(`${config.getValue('apiUrl')}/v1/store/login/user/orders/unsubmitted/items/${orderItemID}/price/calculate`, orderItem)
}
/**
 * Reorders the submitted order item.
 * @method
 * @param {string} orderItemID The unique ID of the submitted order item.
 * @returns {Promise<OrderItemModel>} Returns order item.
 */
export const reorder = async (orderItemID) => {
  return await http.post(`${config.getValue('apiUrl')}/v1/store/login/user/orders/submitted/items/${orderItemID}/reorder`)
}

/**
 * Gets the information about the last submitted order item of a product.
 * @method
 * @param {string} productID The unique ID of the product.
 * @returns {Promise<OrderItemPriceModel>} Returns the requested information about the last submitted order item.
 */
export const getLastOrder = async (productID) => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/login/user/orders/submitted/items/last/status?productID=${productID}`)
}

/**
 * Moves the order item to the user's shopping cart.
 * @method
 * @param {string} orderItemID The unique ID of the order item.
 * @returns {Promise<void>}
 */
export const addToCart = async (orderItemID) => {
  return await http.post(`${config.getValue('apiUrl')}/v1/store/login/user/orders/unsubmitted/items/${orderItemID}/addToCart`)
}

/**
 * Moves the order item to the user's wish list
 * @method
 * @param {string} orderItemID The unique ID of the order item.
 * @returns {Promise<void>}
 */
export const saveForLater = async (orderItemID) => {
  return await http.post(`${config.getValue('apiUrl')}/v1/store/login/user/orders/unsubmitted/items/${orderItemID}/saveForLater`)
}

/**
 * Gets collection of delivery services
 * @typedef MailingServicesModel
 * @property {DeliveryServicesModel[]} MailingServices Collection of delivery services
 */

/**
 * Gets collection of delivery services
 * @param {string} orderItemID The unique ID of the order item.
 * @returns {Promise<MailingServicesModel>} Returns collection of delivery services
 */
export const getDeliveryServices = async (orderItemID) => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/login/user/orders/unsubmitted/items/${orderItemID}/deliveryServices`)
}

export const getProperties = async (orderItemID) => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/login/user/orders/unsubmitted/items/${orderItemID}/properties/form`)
}

export const updateProperties = async (orderItemID, data) => {
  return await http.put(`${config.getValue('apiUrl')}/v1/store/login/user/orders/unsubmitted/items/${orderItemID}/properties/form`, data)
}

export const updatePropertiesState = async (orderItemID, data) => {
  return await http.put(`${config.getValue('apiUrl')}/v1/store/login/user/orders/unsubmitted/items/${orderItemID}/properties/form/state`, data)
}

/**
 * Appends files to the order item property.
 * @param {GUID} orderItemID The unique ID of the order item.
 * @param {GUID} propertyID The unique ID of the property.
 * @param {File} data
 * @returns {Promise<OrderItemFileAttachmentPropertyModel>}
 */
export const uploadFile = async (orderItemID, propertyID, data) => {
  return await httpUpload(`${config.getValue('apiUrl')}/v1/store/login/user/orders/unsubmitted/items/${orderItemID}/properties/${propertyID}/files`, data)
}

/**
 * Delete files of the order item property
 * @param {GUID} orderItemID The unique ID of the order item.
 * @param {GUID} propertyID The unique ID of the property.
 * @param {string[]} names File names collection.
 * @returns {Promise<OrderItemFileAttachmentPropertyModel>}
 */
export const deleteFiles = async (orderItemID, propertyID, names) => {
  return await httpDeleteFiles(`${config.getValue('apiUrl')}/v1/store/login/user/orders/unsubmitted/items/${orderItemID}/properties/${propertyID}/files?${names}`)
}

/**
 * @typedef FilesTypeModel
 * @property {string} MimeType The MIME type of the file.
 * @property {string} Path The path to the file.
 */

/**
 * @typedef FileRepositoriesDetails
 * @property {FilesTypeModel[]} Files The array of files belonging to the repository.
 * @property {GUID} ID The unique identifier of the repository.
 * @property {string} Name  The name of the repository.
 */

/**
 * Gets repository details
 * @param {GUID} guid The unique ID
 * @returns {Promise<FileRepositoriesDetails>}
 */
export const getRepositoryDetails = async (guid) => {
  return await http.get(`${config.getValue('apiUrl')}/v1/system/fileRepositories/${guid}`)
}

/**
 * Gets the general cart information of the current user.
 * @method
 * @param {string} listId The unique identifier.
 * @returns {Promise<CartModel>} Returns the general cart information of the current user.
 */
export const getCartInfo = async (listId = null) => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/login/user/orders/cart?ListID=${listId}`)
}

/**
 * The wish list information
 * @typedef {CartItemModel[]} CartItemModelArray
 */

/**
 * Gets the wish list information of the current user.
 * @method
 * @returns {Promise<CartItemModelArray>} Returns the wish list information of the current user.
 */
export const getWishListInfo = async () => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/login/user/orders/wishList`)
}

/**
 * Deletes the order item from the Cart, Wish list or Drafts.
 * @method
 * @param {string} orderItemID The unique ID of the order item. !!!globally unique identifier
 */
export const deleteCartItem = async (orderItemID) => {
  return await http.delete(`${config.getValue('apiUrl')}/v1/store/login/user/orders/unsubmitted/items/${orderItemID}`)
}

/**
 * Clears the cart of the current user.
 * @method
 * @param {GUID[]} orderItemIds The list of the cart item identifiers. Can be null. If it is null then all cart items will be removed.
 * @param {string} listId The unique ID of the submission list. !!!globally unique identifier
 * @returns {Promise<void>}
 */
export const clearCart = async (orderItemIds = null, listId = null) => {
  return await http.post(`${config.getValue('apiUrl')}/v1/store/login/user/orders/cart/clear`, {
    OrderItemIDs: orderItemIds,
    ListID: listId,
  })
}

/**
 * Clears the wish list of the current user.
 * @method
 * @param {GUID[]} orderItemIds The list of the wish list item identifiers. Can be null. If it is null then all wish list items will be removed.
 * @returns {Promise<void>}
 */
export const clearWishlist = async (orderItemIds = null) => {
  return await http.post(`${config.getValue('apiUrl')}/v1/store/login/user/orders/wishList/clear`, {
    OrderItemIDs: orderItemIds
  })
}

/**
 * Collection of CartItemValidationModel
 * @typedef {CartItemValidationModel[]} CartItemValidationModelArray
 */

/**
 * Validates the cart of the current user.
 * @method
 * @param {boolean} isChecked
 * @param {GUID} listId
 * @returns {Promise<CartItemValidationModelArray>} Collection of CartItemValidationModel
 */
export const validateCart = async (isChecked = null, listId = null) => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/login/user/orders/cart/validations?isChecked=${isChecked}&ListID=${listId}`)
}

/**
 * Validates the wish list of the current user.
 * @method
 * @returns {Promise<CartItemValidationModelArray>} Collection of CartItemValidationModel
 */
export const validateWishList = async () => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/login/user/orders/wishList/validations`)
}

/**
 * Gets the cart pricing information of the current user.
 * @method
 * @param {boolean} isChecked
 * @param {GUID} listId
 * @returns {Promise<CartPriceModel>} Returns cart pricing information of the current user.
 */
export const getCartPricingInfo = async (isChecked = null, listId = null) => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/login/user/orders/cart/price?isChecked=${isChecked}&ListID=${listId}`)
}

/**
 * Gets the wish list pricing information of the current user.
 * @method
 * @returns {Promise<CartPriceModel>} Returns wish list pricing information.
 */
export const getWishListPricingInfo = async () => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/login/user/orders/wishList/price`)
}

/**
 * Updates the item of the cart of the current user.
 * @method
 * @param {GUID} orderItemID The unique ID of the cart item.
 * @param {CartItemUpdateModel} orderItem The cart item update model.
 * @returns {Promise<void>}
 */
export const updateCartItem = async (orderItemID, orderItem) => {
  return await http.put(`${config.getValue('apiUrl')}/v1/store/login/user/orders/cart/${orderItemID}`, orderItem)
}

/**
 * Updates the item of the wish list of the current user.
 * @method
 * @param {GUID} orderItemID The unique ID of the wish list item.
 * @param {WishListItemUpdateModel} orderItem The wish list item update model.
 * @returns {Promise<void>}
 */
export const updateWishListItem = async (orderItemID, orderItem) => {
  return await http.put(`${config.getValue('apiUrl')}/v1/store/login/user/orders/wishList/${orderItemID}`, orderItem)
}

/**
 * Updates the items of the cart of the current user.
 * @method
 * @param {CartItemUpdateModel[]} cartItem The list of the cart items.
 * @returns {Promise<void>}
 */
export const updateCart = async (cartItem) => {
  return await http.put(`${config.getValue('apiUrl')}/v1/store/login/user/orders/cart`, cartItem)
}

/**
 * Creates new cart list.
 * @method
 * @param {string} name Cart list name. Required. Unique.
 * @param {string} externalId Cart list external ID. Optional.
 * @param {string} externalUrl Cart list external URL. Optional.
 * @returns {Promise<CartListCreateResponseModel>} Returns created cart list data.
 */
export const createCartList = async (name, externalId = null, externalUrl = null) => {
  return await http.post(`${config.getValue('apiUrl')}/v1/store/login/user/orders/cart/lists`, {
    Name: name,
    ExternalID: externalId,
    ExternalUrl: externalUrl
  })
}

/**
 * Gets cart list ID be the external ID.
 * @method
 * @param {string} externalId Cart list's external ID.
 * @returns {Promise<GUID>} Returns cart list ID.
 */
export const getListId = async (externalId) => {
  return http.get(`${config.getValue('apiUrl')}/v1/store/login/user/orders/cart/lists/id?externalID=${externalId}`)
}

/**
 * Gets detailed information about cart list by cart list ID.
 * @method
 * @param {GUID} listId Cart list's ID.
 * @returns {Promise<CartListDetailsModel>} Returns detailed cart list data.
 */
export const getListInfo = async (listId) => {
  return http.get(`${config.getValue('apiUrl')}/v1/store/login/user/orders/cart/lists/${listId}/details`)
}

/**
 * A collection of the cart list items.
 * @typedef {CartListModel[]} CartListModelArray
 */

/**
 * Creates new cart list.
 * @method
 * @returns {Promise<CartListModelArray>} Returns collection of the cart list items.
 */
export const getLists = async () => {
  return http.get(`${config.getValue('apiUrl')}/v1/store/login/user/orders/cart/lists`)
}

/**
 * Deletes cart list by ID.
 * @method
 * @param {GUID} listId Cart list's ID.
 * @returns {Promise<void>}
 */
export const deleteList = async (listId) => {
  return http.delete(`${config.getValue('apiUrl')}/v1/store/login/user/orders/cart/lists/${listId}`)
}

/**
 * Exports cart list data by ID to the 3rd party service that was assigned to the store.
 * @method
 * @param {GUID} listId Cart list's ID.
 * @returns {Promise<CartListExportResponseModel>} Returns export results.
 */
export const exportList = async (listId) => {
  return http.post(`${config.getValue('apiUrl')}/v1/store/login/user/orders/cart/lists/${listId}/export`)
}

/**
 * Update cart name data by ID.
 * @method
 * @param {GUID} listId Cart list's ID.
 * @param {string} title Cart name.
 * @returns {Promise<void>}
 */
export const renameList = async (listId, title) => {
  return http.put(`${config.getValue('apiUrl')}/v1/store/login/user/orders/cart/lists/${listId}`, { Name: title })
}

/**
 * Reverts user's cart modification.
 * @method
 * @returns {Promise<void>}
 */
export const revertModifications = async () => {
  return http.post(`${config.getValue('apiUrl')}/v1/store/login/user/orders/cart/approval/revert`, {})
}

/**
 * Duplicates the cart item.
 * @method
 * @param {GUID} orderItemID The unique ID of the cart item.
 * @returns {Promise<ActivityCreateResponseModel>} Returns the result of the activity creation.
 */
export const duplicateItem = async (orderItemID) => {
  return http.post(`${config.getValue('apiUrl')}/v1/store/login/user/orders/cart/${orderItemID}/duplicate`, {})
}

/**
 * Duplicates cart list by ID.
 * @method
 * @param {GUID} listId Cart list's ID.
 * @returns {Promise<ActivityCreateResponseModel>} Returns the result of the activity creation.
 */
export const duplicateList = async (listId) => {
  return http.post(`${config.getValue('apiUrl')}/v1/store/login/user/orders/cart/lists/${listId}/duplicate`, {})
}
