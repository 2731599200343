import './ProductLayout.scss'

/**
 * A component that is loaded for displaying a product of type Kit
 *
 * @param {string} className -the class name to be added to the main container
 *
 * @param children
 */

const ProductLayout = ({ className, children, dynamic }) => {
  if (!children) {
    return null
  }

  return <div className={`${className} product-layout`}>
    <div className={`main-wrapper ${dynamic ? 'dynamic' : 'static'}`}>
      <div className='left'>
        {children.find((child) => { return child.type === 'left' })}
      </div>
      <div className='right'>
        {children.find((child) => { return child.type === 'right' })}
      </div>
    </div>
  </div>
}

export default ProductLayout
