import React, { useRef } from 'react'
import Layout from '../components/layout'
import './Policy.scss'

const PolicyShipping = (props) => {
    return (
      <Layout {...props} className="policy">
        <div className='main-content'>
            <h1 className='top-fix'>Shipping Policy</h1>
            <p>20.1. Print It ZA, nor the Carrier, shall not be liable or responsible for any failure to perform, or delay in performance of, any of its obligations under a 
                Contract that is caused by an Event Outside the Control of Print It ZA or the Carrier.</p>
            <p>20.2. An event outside the control of Print It ZA or the Carrier means any act or event beyond Print It ZA or the Carrier’s reasonable control, including without 
                limitation protests, strikes, lock-outs or other industrial action by third parties, civil commotion, riot, invasion, terrorist attack or threat of 
                terrorist attack, war (whether declared or not) or threat or preparation for war, fire, explosion, storm, flood, earthquake, subsidence, epidemic or 
                other natural disaster, pandemic measures put in place and/or a countries ‘lockdown’ measures imposed by government, load shedding, or failure of 
                public or private telecommunications networks or impossibility of the use of railways, shipping, aircraft, motor transport or other means of public 
                or private transport. Print It ZA obligations under an order or contract will be suspended and the time for performance of its obligations will be 
                extended for the duration of the Event Outside the Control of Print It ZA.</p>
            <p>20.3. When an order or package is not delivered due to an error made by the customer in submitting the proper shipping address, Print It ZA will reship the 
                package to the corrected address however the delivery dates communicated prior will not be considered anymore. The additional costs to reship will be 
                borne by the customer.</p>
            <p>20.4. Should a parcel be lost, the Carrier must be given appropriate time to locate and deliver the parcel. This will affect the delivery times given.</p>
            <p>20.5. Should a Customer be in a postal code area outside of a major city or main centre, these locations will require more time from the Carrier to deliver 
                and therefore the delivery times given must be extended.</p>
            <p>20.6. Once shipped and the Customer has been notified of the tracking number,  it is the responsibility of the Customer to track the parcel until they receive it.</p>
            <p>20.7. Delivery dates are subject to change if there are any errors with the artwork provided, electronic or hardcopy proofs are not approved timeously or 
                payment has not been made or has not reflected in the bank account or an order is put on hold.</p>
            <p>20.8. Delivery is based upon the production time taken by Print It ZA to manufacture the Products and the delivery time to ship the Products to Customer.</p>
            <p>20.9. If Customer is not available at the delivery address, Print It ZA chosen carrier (the Carrier) will leave a note that the Products have been returned to 
                The Carrier’s premises and Customer must contact the Carrier to re-arrange delivery. In circumstances where The Carrier has re-arranged delivery and 
                Customer is not available at the delivery address on the agreed date and time to take delivery of the Products, the Carrier shall be entitled 
                (at its discretion) to charge Customer for any additional costs reasonably incurred by the Carrier in attempting to re-deliver the Products.</p>
            <p>20.10. The Products will be at the risk of the Customer from completion of delivery. Customer shall only own the Products once Print It ZA has received payment 
                in full for the Products.</p>
            <p>20.11. Delivery of the Products shall be performed during normal business hours, being Monday to Friday 8am to 5pm.</p>
            <p>20.12. For the avoidance of doubt, Print It ZA or the Carrier shall not be liable for any delay in delivery of the Products that is caused by an Event Outside 
                the Control of Print It ZA, the Carrier or Customer's failure to provide Print It ZA with adequate delivery instructions or any other instructions that are relevant 
                to the supply of the Products.</p>
        </div>
      </Layout>
    )
}
  
export default PolicyShipping