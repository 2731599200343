import http from './http'
import config from '../services/config'
import { convertObjectToQueryString } from '../utils/utils'

const {
  GUID,
  CategoryModel,
  CategoryTreeModel,
  CategoryListModel,
  CategoryExtendedModel,
 } = require('./../types/categories.js')

/**
 * A list of product categories.
 * @typedef {CategoryModel[]} CategoryModelArray
 */

/**
 * Gets the top level product categories of a store, using pagination. Categories that do not contain online products on any level are filtered out.
 * @method
 * @param {number} pageNumber The requested page number.
 * @param {number} pageSize The number of categories on a single page. The maximum is 200.
 * @returns {Promise<CategoryModelArray>} Returns a list of product categories according the requested parameters.
 */
export const getTopCategories = async (pageNumber, pageSize) => {
  const params = convertObjectToQueryString({ pageNumber, pageSize })
  return await http.get(`${config.getValue('apiUrl')}/v1/store/categories${params.length ? `?${params}` : ''}`)
}

/**
 * Gets the sub-categories of a specific product category, using pagination. Categories that do not contain online products on any level are filtered out.
 * @method
 * @param {GUID} categoryID The unique ID of the parent category.
 * @param {number} pageNumber The requested page number.
 * @param {number} pageSize The number of categories on a single page. The maximum is 200.
 * @returns {Promise<CategoryListModel>} Returns a list of sub-categories according the requested parameters.
 */
export const getSubCategories = async (categoryID, pageNumber, pageSize) => {
  const params = convertObjectToQueryString({ pageNumber, pageSize })
  return await http.get(`${config.getValue('apiUrl')}/v1/store/categories/${categoryID}/categories${params.length ? `?${params}` : ''}`)
}

/**
 * Gets the category's unique ID by its friendly ID.
 * @method
 * @param {number} categoryFriendlyID The friendly ID of the category.
 * @returns {Promise<GUID>} Returns a unique ID for the requested category.
 */
export const getCategoryIDByFriendlyID = async (categoryFriendlyID) => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/categories/id?friendlyID=${categoryFriendlyID}`)
}

/**
 * Gets the category by its unique ID.
 * @method
 * @param {GUID} categoryID The unique ID of the category.
 * @returns {Promise<CategoryExtendedModel>} Returns a category for the requested unique ID. Throws an exception if the requested category does not contain online products on any level.
 */
export const getCategory = async (categoryID) => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/categories/${categoryID}`)
}

/**
 * Gets the tree of product categories of a store.
 * @method
 * @param {number} depth The tree depth.
 * @returns {Promise<CategoryTreeModel>} Returns a tree of product categories according the requested parameters.
 */
export const getCategoryTree = async (depth) => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/categories/tree?depth=${depth}`)
}
